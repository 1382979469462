import styled, { css } from "styled-components";

interface MissionProps {
  bonusToken?: boolean;
  success?: boolean;
}

export const MissionCardItem = styled.li`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2px;
`;

export const MissionDayBox = styled.div<MissionProps>`
  display: flex;
  padding: 10px 0;
  border-radius: ${(props) => (props.bonusToken ? "6px 6px 0 0" : "6px")};
  background: ${(props) =>
    props.success ? props.theme.colors.white : props.theme.colors.shadeBg};
  align-items: center;
  flex-direction: column;
  gap: 10px;

  ${(props) =>
    props.success &&
    css`
      box-shadow: ${(props) => props.theme.shadows.cardShadow};
    `}

  ${(props) =>
    !props.success &&
    css`
      svg {
        path {
          fill: ${(props) => props.theme.colors.inactive};
        }
      }
    `}
`;

export const MissionDaySpan = styled.span`
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.2rem;
  font-weight: 600;
`;

export const MissionBonusBox = styled.div<MissionProps>`
  display: flex;
  padding: 10px 0;
  color: ${(props) => props.theme.colors.inactive};
  font-size: 1.3rem;
  border-radius: 0 0 6px 6px;
  background: ${(props) =>
    props.success ? props.theme.colors.white : props.theme.colors.shadeBg};
  align-items: center;
  justify-content: center;
  gap: 2px;

  ${(props) =>
    props.success &&
    css`
      color: ${(props) => props.theme.colors.active};
      box-shadow: ${(props) => props.theme.shadows.cardShadow};
    `}

  ${(props) =>
    !props.success &&
    css`
      svg {
        path {
          fill: ${(props) => props.theme.colors.inactive};
        }
      }
    `}
`;

import styled from "styled-components";

export const PhysicResultList = styled.ul`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const gaugeGroupBox = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: column;
  gap: 10px;
`;

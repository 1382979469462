import { useState } from "react";
import { NoticeList } from "../../molecules/NoticeList";
import { SelectOptions } from "../../molecules/SelectOptions";
import * as S from "./noticeListSection.style";
import {
  NotificationType,
  UserNotification,
} from "../../../pages/Notice/Notice";
import { LoadingSection } from "../LoadingSection";

interface NoticeListSectionProps {
  userNotification: UserNotification;
  isLoading: boolean;
}

export const NoticeListSection = ({
  userNotification,
  isLoading,
}: NoticeListSectionProps) => {
  const [data, setData] = useState<NotificationType>("notice");
  const options = [
    {
      id: "notice",
      name: "공지알림",
    },
    {
      id: "userFcm",
      name: "사용자알림",
    },
  ];

  return (
    <S.NoticeListSection>
      <SelectOptions
        bgTheme="dark"
        itemCount={2}
        options={options}
        currentOption={data}
        setData={setData}
      />
      {isLoading ? (
        <LoadingSection />
      ) : (
        <NoticeList
          notifications={userNotification[data]}
          notificationType={data}
        />
      )}
    </S.NoticeListSection>
  );
};

import * as S from "./evalLabel.style";

export type EvalStatus = "bad" | "caution" | "worried" | "fine" | "good";

interface EvalLabelProps {
  status: EvalStatus;
}

const evalStatusLabel = {
  bad: "매우위험",
  caution: "미흡",
  worried: "경고",
  fine: "보통",
  good: "우수",
};

export const EvalLabel = ({ status }: EvalLabelProps) => {
  return (
    <S.EvalLabelBox status={status}>{evalStatusLabel[status]}</S.EvalLabelBox>
  );
};

import { Hmt } from "../../molecules/WalkResultList";
import { EvalLabel, EvalStatus } from "../EvalLabel";
import { ItemColumn } from "./ItemColumn";
import { ItemRow } from "./ItemRow";
import * as S from "./walkResultItem.style";

interface WalkResultItemProps {
  colSpan?: number;
  title?: string;
  grade?: EvalStatus;
  item?: any;
  ItemSingle?: boolean;
  threeStep?: boolean;
  children?: React.ReactElement;
  data?: number | string;
  evalData?: number;
  unit?: string;
  left?: string;
  right?: string;
  hmt?: Hmt;
}

export const WalkResultItem = ({
  colSpan,
  title,
  grade,
  item,
  ItemSingle,
  threeStep,
  children,
  data,
  evalData,
  unit,
  left = "",
  right = "",
  hmt,
}: WalkResultItemProps) => {
  return (
    <S.WalkResultItem ItemSingle={ItemSingle} colSpan={colSpan}>
      {ItemSingle && (
        <ItemColumn
          title={title}
          grade={grade}
          item={item}
          children={children}
          data={data}
          unit={unit}
        />
      )}
      {!ItemSingle && (
        <ItemRow
          left={left}
          right={right}
          title={title}
          grade={grade}
          item={item}
          children={children}
          threeStep={threeStep}
          unit={unit}
          hmt={hmt}
        />
      )}
    </S.WalkResultItem>
  );
};

import { useState } from "react";
import ExpModal from "../../molecules/Modal/ExpModal";
import * as S from "./gaitResultItem.style";
import { ReactComponent as IconInfo } from "../../../styles/assets/svg/icon-info.svg";

export const GaitResultItem = ({ surveyResult }: any) => {
  const [modalState, setModalState] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleModalState = (e: string) => {
    setModalState(!modalState);
    setModalContent(e);
  };

  return (
    <>
      {/* <S.GaitResultItem>
        <S.ItemRowGroup>
          <S.ItemRowGroup></S.ItemRowGroup>
        </S.ItemRowGroup>
      </S.GaitResultItem> */}

      <S.GaitResultItem>
        {/* ENV */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitEnv")}>
            <span>ENV(외주면적)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_env}
            <S.ItemUnit>mm²</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* REC */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitRec")}>
            <span>REC(단형면적)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_rec}
            <S.ItemUnit>mm²</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* RMS */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitRms")}>
            <span>RMS(실효가면적)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_rms}
            <S.ItemUnit>mm²</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* TLC */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitTlc")}>
            <span>TLC(중심점거리)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_tlc}
            <S.ItemUnit>mm</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* Total Length */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitTotalLength")}>
            <span>Total Length(총 궤적장)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_total_length}
            <S.ItemUnit>mm</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* Sway velocity */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitSwayVelocity")}>
            <span>Sway velocity(COP 속도)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_sway_velccity}
            <S.ItemUnit>mm/s</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>

        {/* Length/EVG */}
        <S.ItemBox>
          <S.ItemTit onClick={() => handleModalState("gaitLengthEnv")}>
            <span>Length/ENV(단위 외주면적 궤적장)</span>
            <IconInfo />
          </S.ItemTit>
          <S.ItemContentBox>
            {surveyResult?.pat_length_env}
            <S.ItemUnit>1/mm</S.ItemUnit>
          </S.ItemContentBox>
        </S.ItemBox>
      </S.GaitResultItem>

      {/* GaitView인자 설명 Modal */}
      {modalState && (
        <ExpModal onClose={handleModalState} item={modalContent} />
      )}
    </>
  );
};

import { atom, selector } from "recoil";

export const modalState = atom({
  key: "modalState",
  default: {
    isOpen: false,
    message: "",
  },
});

export const modalSelector = selector({
  key: "modalSelector",
  get: ({ get }) => {
    return get(modalState);
  },
  set: ({ set }, newValue) => {
    set(modalState, newValue);
  },
});

import styled from "styled-components";

export const TitBox = styled.div`
  display: flex;
  margin: auto;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 700;
  align-items: center;
  gap: 10px;
`;

import styled from "styled-components";

export const NoticeItem = styled.li`
  display: flex;
  padding: 16px 5px;
  border-bottom: 0.6px solid ${(props) => props.theme.colors.noticeBorder};
  flex-direction: column;
  gap: 8px;
`;

export const NoticeTitSpan = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.3rem;
  font-weight: 500;
  align-items: center;
`;

export const NoticeTxtBox = styled.div`
  padding: 0 4px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.2rem;
  white-space: pre-wrap;
`;

export const NoticeDateBox = styled.div`
  padding: 0 4px;
  color: ${(props) => props.theme.colors.tipTxt};
  font-size: 1.3rem;
  font-weight: 600;
  text-align: end;
`;

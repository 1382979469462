import * as S from "./stepTick.style";
import { ReactComponent as IconTick } from "../../../../styles/assets/svg/icon-tick-bar.svg";
import { ReactComponent as IconToken } from "../../../../styles/assets/svg/icon-token-bar.svg";
import { ReactComponent as IconCrown } from "../../../../styles/assets/svg/icon-crown-sm.svg";

export const StepTick = ({ active, item }: any) => {
  if (item === 3000 || item === 5000 || item === 7000) {
    return (
      <S.StepTickItem active={active}>
        <IconToken />
      </S.StepTickItem>
    );
  } else if (item === 10000) {
    return (
      <S.StepTickItem active={active} className="crown">
        <div className="overbar">
          <IconCrown />
        </div>
      </S.StepTickItem>
    );
  } else {
    return (
      <S.StepTickItem active={active}>
        <IconTick />
      </S.StepTickItem>
    );
  }
};

import { Button } from "../../atoms/Button";
import * as S from "./tokenBox.style";
import { ReactComponent as IconToken } from "../../../styles/assets/svg/icon-take-token.svg";
import { ReactComponent as IconTokenSave } from "../../../styles/assets/svg/icon-token-lg.svg";

interface TokenBoxProps {
  availableCoin: number;
  onClick: () => void;
  isReceivingCoin: boolean;
}
export const TokenBox = ({
  availableCoin,
  onClick,
  isReceivingCoin,
}: TokenBoxProps) => {
  return (
    <S.TokenBox>
      <S.TokenSavingBox>
        <S.TitBox>
          <IconTokenSave width={24} height={24} />
          수령가능 토큰
        </S.TitBox>

        <S.TokenStatusBox>
          <S.StatusSpan>{availableCoin.toLocaleString()}</S.StatusSpan>
          INS
        </S.TokenStatusBox>
      </S.TokenSavingBox>
      <Button
        width="90px"
        color="primary"
        fontSize={1.4}
        directColumn={true}
        label="토큰받기"
        disabled={isReceivingCoin}
        onClick={onClick}
      >
        <IconToken />
      </Button>
    </S.TokenBox>
  );
};

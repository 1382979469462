import styled, { css } from "styled-components";

interface ButtonProps {
  width: string;
  height?: number;
  color: string;
  fontSize?: number;
  directColumn: boolean;
  onClick?: any;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height + "px" : "auto")};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) =>
    props.fontSize
      ? props.fontSize + "rem"
      : props.height && props.height < 40
      ? "1.2rem"
      : "1.6rem"};
  font-weight: 600;
  border: none;
  border-radius: 6px;
  background: ${(props) =>
    props.color === "clear" ? "none" : props.theme.colors[props.color]};
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  ${(props) =>
    props.directColumn &&
    css`
      flex-direction: column;
    `}
`;

export const ATTENDANCE = "attendance";
export const COIN = "coin";
export const AVAILABLE_COIN = "availableCoin";
export const OWNED_COIN = "ownedCoin";
export const MESSAGE_HOME = "messageHome";
export const ACHIEVEMENT_MISSIONS = "achievementMissions";
export const WALKING_SPEED_AVG = "walkingSpeedAvg";
export const FCM_LIST = "fcmList";
export const SURVEY_RESULT = "surveyResult";
export const USE_INFO = "useInfo";
export const QNA_LIST = "qnaList";

import styled from "styled-components";

export const TokenStatusBox = styled.div`
  display: grid;
  padding: 20px 0;
  background: url("/assets/images/img-topbar-01.png") no-repeat;
  background-size: cover;
  background-position: bottom;
  grid-template-columns: auto 134px;

  & > div {
    & + div {
      border-left: 1px solid ${(props) => props.theme.colors.white};
    }
  }
`;

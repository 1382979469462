import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ACHIEVEMENT_MISSIONS,
  AVAILABLE_COIN,
  MESSAGE_HOME,
  OWNED_COIN,
} from "../../common/api-codes";
import { StreakStepsMissions } from "../../common/typs";
import { request } from "../../lib/api";
import { EvalStatus } from "../../components/atoms/EvalLabel";

export interface MessageResponse {
  message: string;
  grade: EvalStatus;
}

export function useHome() {
  /**
   * 사용가능한 토큰 수령
   * 성공시: 현재 사용가능한 토큰 refetch
   */
  const { isLoading: isReceivingCoin, mutateAsync: receiveCoin } = useMutation(
    (data) => request({ method: "POST", url: `api/v1/coin/mobile/receiveCoin`, data }),
    {
      onSuccess: (data, variables, context) => {
        ownedCoinnRefetch();
        availableCoinRefetch();
        achievementMissionsRefetch();
      },
    }
  );

  /**
   * 현재 보유중인 토큰
   */
  const { data: ownedCoin, refetch: ownedCoinnRefetch } = useQuery([OWNED_COIN], () =>
    request<{ ownedCoin: number }>({ method: "GET", url: `api/v1/coin/ownedCoin` }).then(
      (res) => res.ownedCoin ?? 0
    )
  );

  /**
   * 현재 수령가능한 토큰
   */
  const { data: coin, refetch: availableCoinRefetch } = useQuery([AVAILABLE_COIN], () =>
    request<{ coin: { availableCoin: number; receivedCoin: number } }>({
      method: "GET",
      url: `api/v1/coin/availableCoin`,
    }).then((res) => res.coin)
  );

  /**
   *  최근 보행데이터 기반 보행상태 메세지
   */
  const { data: messageResponse } = useQuery([MESSAGE_HOME], () =>
    request<MessageResponse>({ method: "GET", url: `api/v1/message` }).then(
      (res) => res ?? { message: "", grade: 0 }
    )
  );

  /**
   * - 달성한 미션조회
   */
  const { data: achievementMissions, refetch: achievementMissionsRefetch } = useQuery(
    [ACHIEVEMENT_MISSIONS],
    () =>
      request<StreakStepsMissions[]>({
        method: "GET",
        url: `/api/v1/streak-steps-missions/achievement-missions`,
      }).then((res) => res)
  );

  return {
    ownedCoin: ownedCoin ?? 0,
    availableCoin: coin?.availableCoin ?? 0,
    receivedCoin: coin?.receivedCoin,
    message: messageResponse?.message ?? "",
    achievementMissions: achievementMissions ?? [],
    isReceivingCoin,
    receiveCoin,
    availableCoinRefetch,
  };
}

import React from "react";

/** 등급 숫자에 따른 태그 변환  */
const gradeToString = (grade: number) => {
  switch (grade) {
    case 1:
      return "good";
    case 2:
      return "fine";
    case 3:
      return "caution";
    default:
      return "fine";
  }
};

export default gradeToString;

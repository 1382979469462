import styled from "styled-components";

export const loadingSpinner = styled.div`
  width: 64px;
  height: 64px;

  .spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: #f19022;
    border-bottom-color: #f19022;
    animation: spinner 1s ease infinite;
    box-sizing: border-box;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

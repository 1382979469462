import styled, { css } from "styled-components";

interface ItemStyleProps {
  colSpan?: number;
  gridColumn?: number;
  label?: string;
  ItemSingle?: string;
}

export const WalkResultItem = styled.li<ItemStyleProps>`
  display: flex;

  ${(props) =>
    props.ItemSingle === "single" &&
    css`
      padding: 15px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 10px;
      box-shadow: ${(props) => props.theme.shadows.boxShadow};
      flex-direction: column;
      gap: 5px;
    `}

  ${(props) =>
    props.ItemSingle === "double" &&
    css`
      padding: 15px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 10px;
      box-shadow: ${(props) => props.theme.shadows.boxShadow};
      gap: 5px;
      flex-direction: column;
    `}
 

    ${(props) =>
    props.colSpan &&
    css`
      grid-column: 1 / ${props.colSpan};
    `}

  ${(props) =>
    props.ItemSingle === "fourth" &&
    css`
      padding: 15px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 10px;
      box-shadow: ${(props) => props.theme.shadows.boxShadow};
      gap: 5px;
      flex-direction: column;
    `}

    ${(props) =>
    props.colSpan &&
    css`
      grid-column: 1 / ${props.colSpan};
    `}
`;

export const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitBox = styled.div`
  display: flex;

  span {
    display: flex;
    height: 24px;
    padding: 0 4px;
    color: ${(props) => props.theme.colors.mainTxt};
    font-size: 1.5rem;
    font-weight: 800;
    align-items: center;
    gap: 1px;
    cursor: pointer;

    &:active {
      background: ${(props) => props.theme.colors.shadeBg};
      border-radius: 4px;
    }
  }
`;

export const ContentBox = styled.div`
  display: flex;
  font-size: 2.5rem;
  font-weight: 800;
  align-items: baseline;
  justify-content: flex-end;
  gap: 3px;
`;

export const UnitSpan = styled.span`
  color: ${(props) => props.theme.colors.shadeTxt};
  font-size: 1.5rem;
  font-weight: 600;
`;

import { VFC } from "react";
import { SurveyResult } from "../../../pages/Result/Result";
import { PhysicResultList } from "../../molecules/PhysicResultList";
import * as S from "./physicResultSection.style";

export const PhysicResultSection = ({ surveyResult }: any) => {
  return (
    <S.PhysicResultSection>
      <S.TitBox>체력측정 분석결과</S.TitBox>
      <PhysicResultList surveyResult={surveyResult} />
    </S.PhysicResultSection>
  );
};

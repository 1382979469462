import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgStride01 from "../../../../styles/assets/img/img-stride.png";

export const ImgStride = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img
            src={ImgStride01}
            alt="발의 장축의 진행방향에 대한 각도 이미지"
          />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          보폭은 한쪽 발의 발꿈치 접지 시점에서 반대쪽 발꿈치 접지 지점까지의
          거리입니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          환자의 경우 거리를 벌기위해 좌우 비대칭의 보행을 하는 경우가 있는데
          이는 좌우의 보폭을 비교함으로써 판명됩니다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

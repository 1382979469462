import styled, { css } from "styled-components";

interface TickProps {
  active: boolean;
}

export const StepTickItem = styled.li<TickProps>`
  display: flex;
  height: 30px;
  align-items: center;
  justfy-content: center;

  &:first-child {
    width: 11px;
    visibility: hidden;
  }

  &.crown {
    position: relative;
    width: 11px;

    .overbar {
      position: absolute;
      display: flex;
      width: 22px;
      height: 30px;
      background: ${(props) => props.theme.colors.shadeBg};
      border-radius: 0 15px 15px 0;
      align-items: center;
    }
  }

  svg {
    position: relative;
    right: 10px;

    path,
    circle {
      fill: ${(props) => props.theme.colors.inactive};
    }
  }

  ${(props) =>
    props.active &&
    css`
      svg {
        path,
        circle {
          fill: ${(props) => props.theme.colors.white};
        }
      }

      &.crown {
        .overbar {
          background: none;
        }

        svg {
          path,
          circle {
            fill: ${(props) => props.theme.colors.active};
          }
        }
      }
    `}
`;

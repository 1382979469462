import { memo } from "react";
import { TokenStatus } from "../../molecules/TokenStatus";
import * as S from "./topSection.style";

interface TopSectionProps {
  ownedCoin: number;
}
const TopSection = ({ ownedCoin }: TopSectionProps) => {
  return (
    <S.TopSection>
      <TokenStatus ownedCoin={ownedCoin} />
    </S.TopSection>
  );
};

export default memo(TopSection);

import styled, { css } from "styled-components";
import ImgStepLeftTop from "../../../styles/assets/img/img-l-top.png";
import ImgStepLeftMiddle from "../../../styles/assets/img/img-l-middle.png";
import ImgStepLeftBottom from "../../../styles/assets/img/img-l-bottom.png";
import ImgStepRightTop from "../../../styles/assets/img/img-r-top.png";
import ImgStepRightMiddle from "../../../styles/assets/img/img-r-middle.png";
import ImgStepRightBottom from "../../../styles/assets/img/img-r-bottom.png";

interface StepSideProps {
  stepSide?: string;
  stepLocate?: string;
}

export const WalkThreeStep = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
`;

export const StepSideTit = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.3rem;
  font-weight: 500;
`;

export const StepSideBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
`;

export const StepSideSpan = styled.span<StepSideProps>`
  display: flex;
  width: 58px;
  height: 50px;
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background-size: contain !important;
  gap: 1px;

  ${(props) =>
    props.stepSide === "L" &&
    props.stepLocate === "top" &&
    css`
      background: url(${ImgStepLeftTop});
    `}

  ${(props) =>
    props.stepSide === "L" &&
    props.stepLocate === "middle" &&
    css`
      background: url(${ImgStepLeftMiddle});
    `}

      ${(props) =>
    props.stepSide === "L" &&
    props.stepLocate === "bottom" &&
    css`
      padding-left: 15px;
      background: url(${ImgStepLeftBottom});
    `}

        ${(props) =>
    props.stepSide === "R" &&
    props.stepLocate === "top" &&
    css`
      background: url(${ImgStepRightTop});
    `}

          ${(props) =>
    props.stepSide === "R" &&
    props.stepLocate === "middle" &&
    css`
      background: url(${ImgStepRightMiddle});
    `}

            ${(props) =>
    props.stepSide === "R" &&
    props.stepLocate === "bottom" &&
    css`
      padding-right: 15px;
      background: url(${ImgStepRightBottom});
    `}
`;

export const StepSideUnit = styled.span`
  color: ${(props) => props.theme.colors.unitTxt};
  font-weight: 500;
`;

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";

export const ImgTotalLength = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 설명-1 */}
        <S.ContentBox>
          총 궤적장은 검사시간 동안 압력중심점의 총 이동길이를 말하며 단위는 mm
          이다. Total Length의 경우 측정값이 낮을수록 상대적으로 더 나은
          평형성을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import styled from "styled-components";

export const MissionSection = styled.section`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  gap: 16px;
`;

export const MissionBox = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  flex-direction: column;
  gap: 30px;
`;

export const TokenBox = styled.div`
  display: flex;
`;

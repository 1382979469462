import styled, { css } from "styled-components";

interface ItemStyleProps {
  colSpan?: number;
  gridColumn?: number;
  label?: string;
  ItemSingle?: boolean;
}

export const WalkResultItem = styled.li<ItemStyleProps>`
  display: flex;

  ${(props) =>
    props.ItemSingle === true &&
    css`
      padding: 15px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 10px;
      box-shadow: ${(props) => props.theme.shadows.boxShadow};
      flex-direction: column;
      gap: 5px;
    `}

  ${(props) =>
    props.ItemSingle === false &&
    css`
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    `}

    ${(props) =>
    props.colSpan &&
    css`
      grid-column: 1 / ${props.colSpan};
    `}
`;

export const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitBox = styled.div`
  display: flex;
  min-width: 50px;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.4rem;
  font-weight: 600;
  align-items: center;
`;

export const ContentBox = styled.div`
  display: flex;
  font-size: 2.5rem;
  font-weight: 800;
  align-items: baseline;
  justify-content: flex-end;
  gap: 3px;
`;

export const UnitSpan = styled.span`
  color: ${(props) => props.theme.colors.shadeTxt};
  font-size: 1.5rem;
  font-weight: 600;
`;

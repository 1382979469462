import { Hmt } from "../../../molecules/WalkResultList";
import { EvalLabel, EvalStatus } from "../../EvalLabel";
import { WalkThreeStep } from "../../WalkThreeStep";
import * as S from "../walkResultItem.style";
import * as T from "./itemRow.style";
import { ReactComponent as IconInfo } from "../../../../styles/assets/svg/icon-info.svg";

interface WalkResultItemProps {
  title?: string;
  grade?: EvalStatus;
  item?: any;
  ItemSingle?: boolean;
  threeStep?: boolean;
  children?: React.ReactElement;
  left: string;
  right: string;
  unit?: string;
  subTitL?: string;
  subTitR?: string;
  onClick?: any;
}

export const ExbodyItemRow = ({
  title,
  grade,
  children,
  left,
  right,
  unit = "%",
  subTitL,
  subTitR,
  onClick,
}: WalkResultItemProps) => {
  return (
    <>
      <S.TitBox onClick={onClick}>
        <span>
          {title}
          <IconInfo />
        </span>
      </S.TitBox>
      <T.StepSideBox>
        <T.StepSideItem>
          <div>{subTitL} : </div>
          {left}
          <T.StepSideUnit>{unit}</T.StepSideUnit>
        </T.StepSideItem>
        <T.StepSideItem>
          <div>{subTitR} : </div>
          {right}
          <T.StepSideUnit>{unit}</T.StepSideUnit>
        </T.StepSideItem>
      </T.StepSideBox>
      {grade && <EvalLabel status={grade} />}
    </>
  );
};

import { useRecoilState } from "recoil";
import { modalSelector } from "../../../../states/modal";
import { Button } from "../../../atoms/Button";
import * as S from "../modal.style";

const MessageModal = () => {
  const [modalState, setModalState] = useRecoilState(modalSelector);

  const handleClose = () => {
    setModalState({
      isOpen: false,
      message: "",
    });
  };

  return (
    <>
      {modalState.isOpen && (
        <>
          <S.ModalBG>
            <S.ModalBox size="md">
              <S.ContentBox>{modalState.message}</S.ContentBox>

              <Button
                width="100%"
                height={46}
                color="primary"
                fontSize={1.6}
                directColumn={false}
                label="확인"
                onClick={handleClose}
              />
            </S.ModalBox>
          </S.ModalBG>
        </>
      )}
    </>
  );
};

export default MessageModal;

import styled from "styled-components";

export const TokenWalletBox = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  flex-direction: column;
`;

export const TitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitSpan = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5rem;
  font-weight: 500;
  align-items: center;
  gap: 6px;
`;

export const CountBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white};
  font-size: 2.6rem;
  font-weight: 800;
  align-items: baseline;
  justify-content: flex-end;
  gap: 4px;
`;

export const CountUnit = styled.span`
  font-size: 1.8rem;
  font-weight: 400;
`;

import { WalkItem } from "../../atoms/WalkItem";
import * as S from "./walkStatus.style";

export const WalkStatus = ({ data }: any) => {
  function CommaFormat(e: any) {
    return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const CalcDistance = 0.001 * data.distance;

  return (
    <S.WalkStatusList>
      <WalkItem item={CommaFormat(data.step)} name="step" />
      <WalkItem item={CalcDistance.toFixed(3)} name="distance" />
      <WalkItem item={data.speed} name="speed" />
      <WalkItem item={CommaFormat(data.calories)} name="calories" />
    </S.WalkStatusList>
  );
};

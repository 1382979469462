import styled from "styled-components";

export const LoadingSection = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 200px;
  align-items: center;
  justify-content: center;
`;

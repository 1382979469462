import styled, { css } from "styled-components";

interface ItemProps {
  bgTheme: string;
  active: boolean;
}

export const OptionItem = styled.li<ItemProps>`
  display: flex;
  height: 36px;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  border-radius: 18px;

  ${(props) =>
    props.active === true &&
    css`
      color: ${(props) => props.theme.colors.white};
      font-weight: 700;
      background: ${(props) => props.theme.colors.primary};
      box-shadow: ${(props) => props.theme.shadows.cardShadow};
    `}
`;

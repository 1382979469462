import React, { ButtonHTMLAttributes } from "react";
import * as S from "./button.style";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width: string;
  height?: number;
  directColumn: boolean;
  color: string;
  fontSize?: number;
  label?: string;
  onClick?: any;
  children?: any;
}

export const Button: React.FC<ButtonProps> = ({
  width,
  height,
  directColumn,
  color,
  fontSize,
  label,
  onClick,
  children,
  ...rest
}) => {
  return (
    <S.Button
      width={width}
      height={height}
      color={color}
      fontSize={fontSize}
      directColumn={directColumn}
      onClick={onClick}
      {...rest}
    >
      {children}
      {label && label}
    </S.Button>
  );
};

import { MissionCard } from "../../atoms/MissionCard";
import * as S from "./countinueMission.style";
import { ReactComponent as IconCrown } from "../../../styles/assets/svg/icon-crown-tit.svg";
import { MissionBonus, StreakStepsMissions } from "../../../common/typs";

interface CountinueMissionProps {
  achievementMissions: StreakStepsMissions[];
}

export const CountinueMission = ({ achievementMissions }: CountinueMissionProps) => {
  const msssionBounus: MissionBonus[] = getMissionBonus(achievementMissions);
  const consecutiveDays = msssionBounus
    .filter((obj) => obj.success)
    .reduce((acc, curr) => {
      return curr.streak > acc ? curr.streak : acc;
    }, 0);
  return (
    <S.CountinueMissionBox>
      <S.TitBox>
        <S.TitSpan>
          <IconCrown width={24} height={24} />
          연속달성 보너스
        </S.TitSpan>
        <S.ChearTxtBox>
          <S.ChearTxtSpan>{consecutiveDays}일 연속</S.ChearTxtSpan>&nbsp;10,000보 걷기 달성!
        </S.ChearTxtBox>
      </S.TitBox>
      <S.MissionList>
        {msssionBounus.map((msssionBounus: MissionBonus, index: number) => {
          return <MissionCard msssionBounus={msssionBounus} key={index} />;
        })}
      </S.MissionList>
    </S.CountinueMissionBox>
  );
};

function getMissionBonus(achievementMissions: StreakStepsMissions[]): MissionBonus[] {
  const lastStreak = achievementMissions[0]?.streak ?? 0;
  const defaultMissionBonus: MissionBonus[] = new Array(5).fill({}).map((_, index: number) => {
    const streak = lastStreak + index + 1;
    return {
      id: index,
      streak: streak,
      reward: getReward(streak),
      success: false,
    };
  });

  if (achievementMissions.length === 0) {
    return defaultMissionBonus;
  }

  const missions = achievementMissions
    .map((mission: StreakStepsMissions) => {
      return {
        id: mission.id,
        streak: mission.streak,
        reward: mission.reward,
        success: true,
      };
    })
    .slice(0, 3)
    .sort((a, b) => a.streak - b.streak);
  console.log(JSON.stringify(missions));
  return [...missions, ...defaultMissionBonus].slice(0, 5);
}

function getReward(streak: number) {
  if (streak < 3) {
    return 0;
  }

  return streak % 3 === 0 ? 50 : 10;
}

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgRms01 from "../../../../styles/assets/img/img-rms.png";

export const ImgRms = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgRms01} alt="실효가면적 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          실효가 면적은 각 프레임 별 COP의 위치 값과 평균값을 통하여 나오는
          면적이며 상대적으로 많이 위치한 부근의 알고리즘을 통해 정원의 면적을
          구한 값을 말하며 단위는 ㎟ 이다. 상기 이미지는 RMS(실효가면적)을
          묘사하고 있으며 면적이 적을수록 평형성이 좋다는 것을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

export const TODAY_WALKING_GOAL_MESSAGE = `마일리지 지급기준

< 일일 기준 >
출석: 50 마일리지
3천 걸음: 100 마일리지
5천 걸음: 100 마일리지
7천 걸음: 100 마일리지
1만 걸음: 200 마일리지

< 연속일 기준 >
1만 걸음 연속 달성 보너스(3일 주기 반복 지급)
연속 3일: 50 마일리지
연속 4일: 10 마일리지
연속 5일: 10 마일리지
연속 6일: 50 마일리지
연속 7일: 10 마일리지
연속 8일: 10 마일리지
...
`;

import { UseInfo } from "../../../pages/UseInfo/UseInfo";
import { UseInfoItem } from "../../atoms/UseInfoItem";
import * as S from "./useInfoList.style";

interface UseInfoListProps {
  useInfo: any;
}

export const UseInfoList = ({ useInfo }: UseInfoListProps) => {
  return (
    <>
      <S.UseInfoList>
        {useInfo.map((info: UseInfo) => (
          <UseInfoItem key={info.id} useInfo={info} />
        ))}
      </S.UseInfoList>
    </>
  );
};

import { useState } from "react";
import { ExbodyResultItem } from "../../atoms/ExbodyResultItem";
import ExpModal from "../Modal/ExpModal";
import * as S from "./exbodyResultList.style";

export const ExbodyResultList = ({ surveyResult }: any) => {
  const [modalState, setModalState] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleModalState = (e: string) => {
    setModalState(!modalState);
    setModalContent(e);
  };

  return (
    <S.ExbodyResultList>
      {/* <S.ExbodyItemGroup>

      </S.ExbodyItemGroup> */}
      <ExbodyResultItem
        ItemSingle="double"
        title="목 신전·굴곡"
        item={true}
        left={surveyResult?.pat_neck_flexion_front}
        right={surveyResult?.pat_neck_flexion_back}
        subTitL="앞"
        subTitR="뒤"
        unit="deg"
        onClick={() => handleModalState("exbodyHeadLocation")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="몸통 전후기울기"
        item={true}
        left={surveyResult?.pat_bodybf_incline_front}
        right={surveyResult?.pat_bodybf_incline_back}
        subTitL="앞"
        subTitR="뒤"
        unit="deg"
        onClick={() => handleModalState("exbodyFactorBackFront")}
      />
      <ExbodyResultItem
        ItemSingle="fourth"
        title="고관절 신전·굴곡"
        item={true}
        leftTop={surveyResult?.pat_hipjoint_flexion_r_front}
        rightTop={surveyResult?.pat_hipjoint_flexion_r_back}
        leftBottom={surveyResult?.pat_hipjoint_flexion_l_front}
        rightBottom={surveyResult?.pat_hipjoint_flexion_l_back}
        subTitLT="앞"
        subTitRT="뒤"
        subTitLB="앞"
        subTitRB="뒤"
        unit="deg"
        onClick={() => handleModalState("exbodyWorkRange")}
      />
      <ExbodyResultItem
        ItemSingle="fourth"
        title="고관절 회전 각도"
        item={true}
        leftTop={surveyResult?.pat_hipjoint_rotation_angle_r_in}
        rightTop={surveyResult?.pat_hipjoint_rotation_angle_r_out}
        leftBottom={surveyResult?.pat_hipjoint_rotation_angle_l_in}
        rightBottom={surveyResult?.pat_hipjoint_rotation_angle_l_out}
        subTitLT="내"
        subTitRT="외"
        subTitLB="내"
        subTitRB="외"
        unit="deg"
        onClick={() => handleModalState("exbodyHipRotation")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="무릎관절 신전·굴곡"
        item={true}
        left={surveyResult?.pat_kneejoint_flexion_l}
        right={surveyResult?.pat_kneejoint_flexion_r}
        subTitL="좌"
        subTitR="우"
        unit="deg"
        onClick={() => handleModalState("exbodyKneeWork")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="몸통 좌우 기울기"
        item={true}
        left={surveyResult?.pat_bodylr_incline_l}
        right={surveyResult?.pat_bodylr_incline_r}
        subTitL="좌"
        subTitR="우"
        unit="deg"
        onClick={() => handleModalState("exbodyFactorLeftRight")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="골반 좌우 이동 변위"
        item={true}
        left={surveyResult?.pat_pelvis_lr_movment_l}
        right={surveyResult?.pat_pelvis_lr_movment_r}
        subTitL="좌"
        subTitR="우"
        unit="deg"
        onClick={() => handleModalState("exbodyHipLeftRight")}
      />
      <ExbodyResultItem
        ItemSingle="single"
        title="수직 이동 변위"
        item={true}
        data={surveyResult?.pat_vertical_movement_up}
        unit="deg"
        onClick={() => handleModalState("exbodyWalkVertical")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="골반 회전 각도"
        item={true}
        left={surveyResult?.pat_pelvic_rotation_angle_l}
        right={surveyResult?.pat_pelvic_rotation_angle_r}
        subTitL="좌"
        subTitR="우"
        unit="deg"
        onClick={() => handleModalState("exbodyBodySpin")}
      />
      <ExbodyResultItem
        ItemSingle="single"
        title="보간"
        item={true}
        data={surveyResult?.pat_step_length}
        subTitL="앞"
        subTitR="뒤"
        unit="deg"
        onClick={() => handleModalState("exbodyStepWidth")}
      />
      <ExbodyResultItem
        ItemSingle="double"
        title="보폭"
        item={true}
        left={surveyResult?.pat_stride_l}
        right={surveyResult?.pat_stride_r}
        subTitL="좌"
        subTitR="우"
        unit="deg"
        onClick={() => handleModalState("exbodyStride")}
      />

      {/* EXBODY인자 설명 Modal */}
      {modalState && (
        <ExpModal onClose={handleModalState} item={modalContent} />
      )}
    </S.ExbodyResultList>
  );
};

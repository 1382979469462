import styled from "styled-components";

export const WalkItem = styled.li`
  display: flex;
  width: 100%;
  padding: 14px 20px 20px 14px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  flex-direction: column;
  gap: 2px;
`;

export const TitBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.4rem;
  font-weight: 600;
  align-items: center;
  gap: 4px;
`;

export const StatusBox = styled.div`
  display: flex;
  font-size: 2.5rem;
  font-weight: 800;
  align-items: baseline;
  justify-content: flex-end;
  gap: 3px;
`;

export const UnitSpan = styled.span`
  color: ${(props) => props.theme.colors.shadeTxt};
  font-size: 1.5rem;
  font-weight: 600;
`;

import styled from "styled-components";

export const ResultModalImg = styled.div`
  overflow: auto;
  display: flex;
  max-height: calc(90vh - 106px);
  flex-direction: column;
  gap: 20px;
`;

export const TitBox = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 1.6rem;
  font-weight: 800;
`;

export const ContentBox = styled.div`
  display: flex;
  padding: 0 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  flex-direction: column;
  gap: 8px;
`;

export const SubtitBox = styled.div`
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.4rem;
  font-weight: 600;
`;

export const TxtGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
`;

export const TxtTitBox = styled.div`
  color: ${(props) => props.theme.colors.mainTxt};
  font-weight: 600;
`;

export const TxtContentBox = styled.div`
  padding: 10px;
  line-height: 2rem;
  background: ${(props) => props.theme.colors.shadeBg};
  border-radius: 6px;
`;

export const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  img {
    width: 100%;
    max-height: 230px;
    object-fit: contain;
  }
`;

export const ImgTitBox = styled.div`
  width: 100%;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.mainTxt};
  font-weight: 600;
`;

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgHeadLocation01 from "../../../../styles/assets/img/img-location-head.png";

export const ImgHeadLocation = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgHeadLocation01} alt="보행시 머리위치 이미지-01" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          보행 시 머리는 가로축의 중심선 상에 정렬되어 있어야 합니다. 머리가
          가로축 중심선보다 앞쪽에 위치하게 되면 중심선보다 1인치(2.54cm)
          앞쪽으로 위치하게 될 때마다 경추는 머리무게(약 4.5kg)만큼의 중력을
          받게 되고 그로 인한 생체역학적 작용으로 인해 근골격 불균형과 부정렬은
          심화되어 집니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>
            • 따라서 머리의 위치를 나타내는 목관절 그래프는 0도 상에 위치해야
            합니다.
          </S.SubtitBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

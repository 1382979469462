import * as S from "./useInfoListSection.style";
import { LoadingSection } from "../LoadingSection";
import { UseInfoList } from "../../molecules/UseInfoList";

interface UseInfoListSectionProps {
  useInfo: any;
  isLoading: boolean;
}

export const UseInfoListSection = ({
  useInfo,
  isLoading,
}: UseInfoListSectionProps) => {
  return (
    <S.UseInfoListSection>
      {isLoading ? <LoadingSection /> : <UseInfoList useInfo={useInfo} />}
    </S.UseInfoListSection>
  );
};

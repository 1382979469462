import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { request, setToken } from "../lib/api";
import { modalSelector } from "../states/modal";
import MessageModal from "./molecules/Modal/MessageModal";

interface AttendanceCheckResponse {
  success: boolean;
  msg?: string;
}

interface CoreProps {
  children: React.ReactElement;
}

function Core({ children }: CoreProps) {
  const setModalState = useSetRecoilState(modalSelector);
  // const testToken =
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdpbHU5MSIsImRldmljZUlkIjoiNTlhMWM1YzItMDIxNy00MDkxLWJjYjMtOGM5NjEwYTdiNGMyIiwiaWQiOjEsImlhdCI6MTY3NzQ3ODg2Nn0.2zI4Ad1cSZDnwyGZ6PdoN2bhjdUZFabeO85SeLfvarU";
  // const [accessToken, setAccessToken] = useState(testToken);
  const defaultToken =
    process.env.NODE_ENV === "development"
      ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdpbHU5MSIsImRldmljZUlkIjoiNTlhMWM1YzItMDIxNy00MDkxLWJjYjMtOGM5NjEwYTdiNGMyIiwiaWQiOjEsImlhdCI6MTY3NzQ3ODg2Nn0.2zI4Ad1cSZDnwyGZ6PdoN2bhjdUZFabeO85SeLfvarU"
      : "";
  const [accessToken, setAccessToken] = useState(defaultToken);

  useEffect(() => {
    const android = window.android;
    if (process.env.NODE_ENV === "development") {
      setToken(defaultToken);
    }
    if (android) {
      const accessToken = android.getAccessToken() ?? "";

      const fcmToken = android.getFcmToken() ?? "";
      if (accessToken !== "") {
        setToken(accessToken);
        setAccessToken(accessToken);
      }
      initComponents(fcmToken);
    }
  }, []);

  const initComponents = async (fcmToken: { fcmToken: string }) => {
    const response: AttendanceCheckResponse = await request({
      method: "GET",
      url: `api/v1/users/attendance`,
    });

    if (fcmToken) {
      await request({
        method: "PUT",
        url: `api/v1/notification/update-fcm`,
        data: {
          fcmToken,
        },
      });
    }

    if (response.success) {
      setModalState({
        isOpen: true,
        message: response.msg ?? "출석체크 완료!",
      });
    }
  };
  return (
    <>
      <MessageModal />
      {accessToken ? children : <></>}
    </>
  );
}

export default Core;

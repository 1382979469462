import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgTlc01 from "../../../../styles/assets/img/img-tlc.png";

export const ImgTlc = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgTlc01} alt="중심점거리 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          중심점 거리는 평균점에서 각각의 프레임 별 COP까지의 거리를 모두 합한
          값이며 단위는 mm이다. 상기 이미지는 TLC(중심점거리)를 묘사하고 있으며
          무게 중심점의 이동거리가 적을수록 평형성이 좋다는 것을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import React, { useState } from "react";
import * as S from "./optionItem.style";

export const OptionItem = ({ bgTheme, active, data, onClick }: any) => {
  return (
    <S.OptionItem bgTheme={bgTheme} active={active} onClick={onClick}>
      {data.name}
    </S.OptionItem>
  );
};

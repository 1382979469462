import { SurveyResult } from "../../../pages/Result/Result";
import { GaitResultItem } from "../../atoms/GaitResultItem";
import { GaitviewImg } from "../../atoms/GaitviewImg";
import * as S from "./gaitviewResultList.style";

export const GaitviewResultList = ({ surveyResult }: any) => {
  return (
    <S.GaitviewResultList>
      <GaitviewImg img={surveyResult?.filename} />
      <GaitResultItem surveyResult={surveyResult} />
    </S.GaitviewResultList>
  );
};

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";

export const ImgSway = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 설명-1 */}
        <S.ContentBox>
          총 궤적장/검사시간을 말하며 단위는 mm/s 이다. Sway Velocity의 경우
          속도가 낮을수록 상대적으로 더 나은 평형성을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgWalkVertical01 from "../../../../styles/assets/img/img-walk-vertical-01.png";
import ImgWalkVertical02 from "../../../../styles/assets/img/img-walk-vertical-02.png";

export const ImgWalkVertical = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgWalkVertical01} alt="수직이동변위 이미지-01" />
          <img src={ImgWalkVertical02} alt="수직이동변위 이미지-02" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          보행주기 내에서 규칙적으로 곡선(sine curve)을 두번이나 그리며, 상하로
          수직이동 합니다. 수직이동 곡선의 정점은 두번의 단하지 지지기로
          양다리가 서로 겹쳐지는 순간이며, 보행주기의 25%와 75% 지점인 중간
          입각기와 중간 유각기에서 나타납니다. 최하위점은 두번의 양하지
          지지기에서 발생하며, 초기의 양하지 지지기 구간인 부하반응기와
          보행주기의 50% 지점인 전유각기에서 발생합니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 성인 남자의 평균 수직 이동거리: 약 2.5cm</S.SubtitBox>
          <S.TxtContentBox>
            ※ 과도한 수직 이동 변위는 골반의 측방 경사 및 회전 또는 족관절과
            슬관절의 협동운동 기전의 문제로 인해 비효율적인 보행을 야기할 수
            있습니다.
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

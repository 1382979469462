import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgHipLeftRight01 from "../../../../styles/assets/img/img-hip-leftright-01.png";
import ImgHipLeftRight02 from "../../../../styles/assets/img/img-hip-leftright-02.png";

export const ImgHipLeftRight = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <S.ImgTitBox>
            • 골반 좌우 이동 변위:(양극에서 측정했을 경우) 약 5cm
          </S.ImgTitBox>
          <img src={ImgHipLeftRight01} alt="골반 좌우 이동변위 이미지-01" />
          <img src={ImgHipLeftRight02} alt="골반 좌우 이동변위 이미지-02" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          한 보행주기 내에서 일어나는 골반 좌우 움직임은 몸통과 함께 1회의
          사인곡선을 그립니다. 골반은 지지하는 다리의 방향으로 움직이며, 중간
          입각기의 마지막(보행주기 31%)과 중간 유각기(보행주기 81%) 시점에서
          가장 큰 좌우 이동값을 나타내고, 보행주기 50%에 중립 포지션에
          위치합니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          이러한 골반 좌우 움직임은 보행 시 신체 동요와 에너지 효율에 중대한
          영향을 미치며, 골반 좌우 기울기와 몸통 좌우 기울기에 직접적으로
          관여합니다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import styled from "styled-components";

export const StepSideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 65px;
`;

export const StepSideItem = styled.div`
  display: flex;
  width: 115px;
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  gap: 1px;
`;

export const StepSideUnit = styled.span`
  color: ${(props) => props.theme.colors.unitTxt};
  font-weight: 500;
`;

export const RowWrapper = styled.div`
  display: flex;
`;

export const DirectionTit = styled.div`
  font-size: 1.2rem;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
`;

import { Hmt } from "../../molecules/WalkResultList";
import { EvalLabel, EvalStatus } from "../EvalLabel";
import { ExbodyItemColumn } from "./ExbodyItemColumn";
import { ExbodyItemRow } from "./ExbodyItemRow";
import { ExbodyItemTwoRow } from "./ExbodyItemTwoRow";
import * as S from "./walkResultItem.style";

interface ExbodyResultItemProps {
  colSpan?: number;
  title?: string;
  grade?: EvalStatus;
  item?: any;
  ItemSingle?: string;
  threeStep?: boolean;
  children?: React.ReactElement;
  data?: number | string;
  evalData?: number;
  unit?: string;
  left?: string;
  right?: string;
  subTitL?: string;
  subTitR?: string;
  leftTop?: string;
  leftBottom?: string;
  rightTop?: string;
  rightBottom?: string;
  subTitLT?: string;
  subTitLB?: string;
  subTitRT?: string;
  subTitRB?: string;
  onClick?: any;
}

export const ExbodyResultItem = ({
  colSpan,
  title,
  grade,
  item,
  ItemSingle,
  threeStep,
  children,
  data,
  evalData,
  unit,
  left = "",
  right = "",
  subTitL,
  subTitR,
  leftTop,
  leftBottom,
  rightTop,
  rightBottom,
  subTitLT,
  subTitLB,
  subTitRT,
  subTitRB,
  onClick,
}: ExbodyResultItemProps) => {
  return (
    <S.WalkResultItem ItemSingle={ItemSingle} colSpan={colSpan}>
      {ItemSingle === "single" && (
        <ExbodyItemColumn
          title={title}
          grade={grade}
          item={item}
          children={children}
          data={data}
          unit={unit}
          onClick={onClick}
        />
      )}
      {ItemSingle === "double" && (
        <ExbodyItemRow
          left={left}
          subTitL={subTitL}
          right={right}
          subTitR={subTitR}
          title={title}
          grade={grade}
          item={item}
          children={children}
          threeStep={threeStep}
          unit={unit}
          onClick={onClick}
        />
      )}
      {ItemSingle === "fourth" && (
        <ExbodyItemTwoRow
          leftTop={leftTop}
          leftBottom={leftBottom}
          rightTop={rightTop}
          rightBottom={rightBottom}
          subTitLT={subTitLT}
          subTitLB={subTitLB}
          subTitRT={subTitRT}
          subTitRB={subTitRB}
          right={right}
          title={title}
          grade={grade}
          item={item}
          children={children}
          threeStep={threeStep}
          unit={unit}
          onClick={onClick}
        />
      )}
    </S.WalkResultItem>
  );
};

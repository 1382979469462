import styled from "styled-components";

export const UseInfoItem = styled.li`
  display: flex;
  padding: 16px 5px;
  border-bottom: 0.6px solid ${(props) => props.theme.colors.noticeBorder};
  flex-direction: column;
  gap: 8px;
`;

export const UseInfoTitSpan = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.black};
  font-size: 1.3rem;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const UseInfoName = styled.div`
  padding: 0 4px;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.2rem;
`;

export const UseInfoTxtNumber = styled.div`
  padding: 0 4px;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.mainTxt};
  line-height: 2.2rem;
`;
export const UseInfoTxtBox = styled.div`
  padding: 0 4px;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mainTxt};
  line-height: 2.2rem;
`;

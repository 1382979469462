import { Button } from "../../../atoms/Button";
import { ImgBodySpin } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgBodySpin";
import { ImgFactorBackFront } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgFactorBackFront";
import { ImgFactorLeftRight } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgFactorLeftRight";
import { ImgHeadLocation } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgHeadLocation";
import { ImgHipHike } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgHipHike";
import { ImgHipLeftRight } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgHipLeftRight";
import { ImgHipRotation } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgHipRotation";
import { ImgHipWorkRange } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgHipWorkRange";
import { ImgKneeWork } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgKneeWork";
import { ImgStepWidth } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgStepWidth";
import { ImgStride } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgStride";
import { ImgWalkVertical } from "../../../atoms/ResultModalImg/ExbodyImgItems/ImgWalkVertical";
import { ImgEnv } from "../../../atoms/ResultModalImg/GaitImgItems/ImgEnv";
import { ImgLengthEnv } from "../../../atoms/ResultModalImg/GaitImgItems/ImgLengthEnv";
import { ImgRec } from "../../../atoms/ResultModalImg/GaitImgItems/ImgRec";
import { ImgRms } from "../../../atoms/ResultModalImg/GaitImgItems/ImgRms";
import { ImgSway } from "../../../atoms/ResultModalImg/GaitImgItems/ImgSway";
import { ImgTlc } from "../../../atoms/ResultModalImg/GaitImgItems/ImgTlc";
import { ImgTotalLength } from "../../../atoms/ResultModalImg/GaitImgItems/ImgTotalLength";
import * as S from "../modal.style";

const ExpModal = ({ onClose, item }: any) => {
  return (
    <S.ModalBG>
      <S.ModalBox size="lg">
        {/* GAITVIEW 인자 */}
        {item === "gaitEnv" && <ImgEnv title="ENV(Envelpe Area, 외주면적)" />}
        {item === "gaitRec" && <ImgRec title="REC(Rectangle, 단형면적)" />}
        {item === "gaitRms" && (
          <ImgRms title="RMS(Root Mean Square, 실효가면적)" />
        )}
        {item === "gaitTlc" && (
          <ImgTlc title="TLC(Total Length from Cop, 중심점거리)" />
        )}
        {item === "gaitTotalLength" && (
          <ImgTotalLength title="Total Length(총 궤적장)" />
        )}
        {item === "gaitSwayVelocity" && (
          <ImgSway title="Sway Velocity(COP 속도)" />
        )}
        {item === "gaitLengthEnv" && (
          <ImgLengthEnv title="Length/ENV(단위 외주면적 궤적장)" />
        )}

        {/* EXBODY 인자 */}
        {item === "exbodyHeadLocation" && (
          <ImgHeadLocation title="보행 시 머리의 위치" />
        )}
        {item === "exbodyFactorBackFront" && (
          <ImgFactorBackFront title="부하 반응기(Loading Response)와 입각중기(Mid Stance)에서 몸통 전방 기울임의 원인" />
        )}
        {item === "exbodyWorkRange" && (
          <ImgHipWorkRange title="고관절에서의 정상적인 운동범위" />
        )}
        {item === "exbodyHipRotation" && (
          <ImgHipRotation title="고관절 회전(Hip Rotation)" />
        )}
        {item === "exbodyKneeWork" && (
          <ImgKneeWork title="무릎관절에서의 정상적인 운동범위" />
        )}
        {item === "exbodyFactorLeftRight" && (
          <ImgFactorLeftRight title="부하 반응기(Loading Response)와 입각중기(Mid Stance)에서 몸통 좌우 기울기의 원인" />
        )}
        {item === "exbodyHipLeftRight" && (
          <ImgHipLeftRight title="골반 좌우 이동 변위" />
        )}
        {item === "exbodyWalkVertical" && (
          <ImgWalkVertical title="수직 이동 변위" />
        )}
        {item === "exbodyBodySpin" && <ImgBodySpin title="과도한 체간회전" />}
        {item === "exbodyStepWidth" && (
          <ImgStepWidth title="보행간격(Step Width)" />
        )}
        {item === "exbodyStride" && <ImgStride title="보폭(Step)" />}

        {/* <ImgHipHike title="골반 들어올림(Hip Hike)" /> */}

        <Button
          width="100%"
          height={46}
          color="primary"
          fontSize={1.6}
          directColumn={false}
          label="닫기"
          onClick={onClose}
        />
      </S.ModalBox>
    </S.ModalBG>
  );
};

export default ExpModal;

import React, { useState } from "react";
import { StepGauge } from "../../atoms/StepGauge";
import * as S from "./dailyMission.style";
import { ReactComponent as IconWalk } from "../../../styles/assets/svg/icon-walk-tit.svg";
import { ReactComponent as IconHelp } from "../../../styles/assets/svg/icon-help.svg";
import { TODAY_WALKING_GOAL_MESSAGE } from "../../../common/strings";

export const DailyMission = ({ step, onPopup }: any) => {
  const handlePopup = () => {
    onPopup(TODAY_WALKING_GOAL_MESSAGE);
  };
  return (
    <S.DailyMissionBox>
      <S.TitBox>
        <S.TitSpan>
          <IconWalk />
          오늘의 걷기 달성
          <IconHelp onClick={handlePopup} />
        </S.TitSpan>
        <S.StepBox>
          <S.StepSpan>{step}</S.StepSpan>
          걸음
        </S.StepBox>
      </S.TitBox>
      <StepGauge walk={step} />
    </S.DailyMissionBox>
  );
};

import styled from "styled-components";

export const WalkSection = styled.section`
  display: flex;
  padding: 16px 20px 40px;
  flex-direction: column;
  gap: 14px;
`;

export const TitBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 700;
  align-items: center;
  gap: 10px;

  svg {
    path {
      stroke: ${(props) => props.theme.colors.mainTxt};
    }
  }
`;

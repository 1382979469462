import styled, { css } from "styled-components";

interface OptionProp {
  bgTheme: string;
  itemCount: number;
}

export const OptionList = styled.ul<OptionProp>`
  display: grid;
  padding: 5px;
  border-radius: 23px;
  color: ${(props) => props.theme.colors.subTxt};
  background: ${(props) =>
    props.bgTheme === "light"
      ? props.theme.colors.white
      : props.theme.colors.shadeBg};
  grid-template-columns: ${(props) =>
    props.itemCount ? "repeat(" + props.itemCount + ", 1fr)" : "1fr"};

  ${(props) =>
    props.bgTheme === "light" &&
    css`
      color: ${(props) => props.theme.colors.mainTxt};
    `};
`;

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgBodySpin01 from "../../../../styles/assets/img/img-body-spin.png";

export const ImgBodySpin = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgBodySpin01} alt="과도한 체간회전 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          정상적인 경우 5도 범위 내에서 회전합니다. 횡단면에서 5도 이상 회전이
          증가하면 체간회전이 과도한 것입니다. 회전성 척추측만은 횡단면에서
          체간부정렬의 정적인 원인이 될 수 있습니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>
            • 과도한 체간회전의 동적 원인[Dynamic Causes of Excessive Trunk
            Rotaion]
          </S.SubtitBox>
          <S.TxtContentBox>
            1. 골반과의 공동작용으로 중간 및 말기 유각기동안 체간의 같은 쪽에서
            과도한 전방회전이 일어납니다. 만약 입각기에 이러한 반응이 나타난다면
            체간은 말기입각기에 과도한 후방회전의 양상을 보입니다.
          </S.TxtContentBox>
          <S.TxtContentBox>
            2. 슬관절 통증으로 같은 쪽을 지지할 때 지팡이는 하지를 따라
            움직이므로 초기접지기나 전 유각기에 후방회전의 정점에서 반대되는
            과도한 전방회전이 나타납니다.
          </S.TxtContentBox>
          <S.TxtContentBox>
            3. 정상적인 체간의 회전이 발생하더라도 균형을 보조하기 위해 과장된
            팔의 움직임을 사용한다면 체간의 회전이 과도해질 수 있습니다.
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgStepWidth01 from "../../../../styles/assets/img/img-step-width.png";

export const ImgStepWidth = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img
            src={ImgStepWidth01}
            alt="발의 장축의 진행방향에 대한 각도 이미지"
          />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          보행 간격은 좌우 발꿈치 중심사이의 폭입니다.
          <br />
          보행시의 보행간격은 진행방향에 대해서 직각으로 측정합니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 정상 보행간격: 5~13cm</S.SubtitBox>
          <S.TxtContentBox>
            가만히 서있을 때의 발의 장축은 진행방향에 대해서 각각 7도 외회전
            하고 있습니다.
            <br />
            이러한 발의 외회전(Toe Out Angle)은 보행시에도 관찰됩니다.
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import styled from "styled-components";

interface GaugeBarProps {
  width?: number;
}

export const WalkResultGauge = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 110px 1fr;
  gap: 10px;
`;

export const TitBox = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.unitTxt};
  align-items: center;
  justify-content: space-between;
`;

export const ContentBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.black};
  font-size: 1.6rem;
  align-items: center;
  gap: 2px;
`;

export const UnitSpan = styled.span`
  color: ${(props) => props.theme.colors.unitTxt};
  font-weight: 500;
`;

export const GaugeBarBox = styled.div`
  display: flex;
  width: 95%;
  height: 20px;
  padding: 3px;
  background: ${(props) => props.theme.colors.shadeBg};
  border-radius: 10px;
`;

export const GaugeBar = styled.div<GaugeBarProps>`
  width: ${(props) => props.width + "%"};
  height: 14px;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 7px;
`;

export const GaugeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

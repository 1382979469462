import { useQuery } from "@tanstack/react-query";
import { NoticeListSection } from "../../components/organisms/NoticeListSection";
import { NoticeTemplate } from "../../components/templates/NoticeTemplate";
import { FCM_LIST, USE_INFO } from "../../common/api-codes";
import { request } from "../../lib/api";
import { UseInfoListSection } from "../../components/organisms/UseInfoListSection";

export type NotificationType = "notice" | "userFcm";

export interface UseInfo {
  id: number;
  name: string;
  address: string;
  phone_number: string;
}

export interface UseInfoData {
  useInfo: UseInfo[];
}

export default function UseInfo() {
  const { data, isLoading } = useQuery([USE_INFO], () =>
    request<{ useInfo: UseInfoData }>({
      method: "GET",
      url: `api/v1/useInfo`,
    }).then((res) => {
      return res.useInfo;
    })
  );

  return (
    <NoticeTemplate>
      <UseInfoListSection useInfo={data} isLoading={isLoading} />
    </NoticeTemplate>
  );
}

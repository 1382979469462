import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";

export const ImgLengthEnv = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 설명-1 */}
        <S.ContentBox>
          총 궤적장/외주면적으로 Sway가 안정적일수록 값이 높아지며 단위는 1/mm
          이다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import gradeToString from "../../../lib/gradeToString";
import { WalkResultGauge } from "../../atoms/WalkResultGauge";
import { WalkResultItem } from "../../atoms/WalkResultItem";
import * as S from "./physicResultList.style";

export const PhysicResultList = ({ surveyResult }: any) => {
  return (
    <S.PhysicResultList>
      <WalkResultItem
        ItemSingle={true}
        title="근력(악력)"
        item={true}
        colSpan={3}
      >
        <S.gaugeGroupBox>
          <WalkResultGauge
            title="오른손"
            gaugeData={surveyResult?.R_gripstrength_average}
            grade={gradeToString(surveyResult?.pat_right_gripstrength_eval)}
          />
          <WalkResultGauge
            title="왼손"
            gaugeData={surveyResult?.L_gripstrength_average}
            grade={gradeToString(surveyResult?.pat_left_gripstrength_eval)}
          />
        </S.gaugeGroupBox>
      </WalkResultItem>
      <WalkResultItem
        ItemSingle={true}
        title="하지근력"
        grade={gradeToString(surveyResult?.pat_sit_n_up_eval)}
        item={true}
        data={surveyResult?.pat_sit_n_up}
        unit="(회)"
      />
      {surveyResult?.pat_age > 64 ? (
        // 8자 보행
        <WalkResultItem
          ItemSingle={true}
          title="협응력"
          grade={gradeToString(surveyResult?.pat_eightwalk_eval)}
          item={true}
          data={surveyResult?.pat_eightwalk}
          unit="(초)"
        />
      ) : (
        // 제자리 높이뛰기
        <WalkResultItem
          ItemSingle={true}
          title="순발력"
          grade={gradeToString(surveyResult?.pat_standing_highjump_eval)}
          item={true}
          data={surveyResult?.pat_standing_highjump}
          unit="(초)"
        />
      )}
    </S.PhysicResultList>
  );
};

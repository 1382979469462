import styled from "styled-components";

export const ResultTemplate = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  padding: 16px 20px;
  background: ${(props) => props.theme.colors.mainBg};
  flex-direction: column;
  gap: 60px;
`;

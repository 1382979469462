import { useEffect, useState } from "react";
import { StreakStepsMissions, Walk } from "../../common/typs";
import { LoadingSection } from "../../components/organisms/LoadingSection";
import { MissionSection } from "../../components/organisms/MissionSection";
import MonitorSection from "../../components/organisms/MonitorSection";
import TopSection from "../../components/organisms/TopSection";
import WalkSection from "../../components/organisms/WalkSection";
import { MainTemplate } from "../../components/templates/MainTemplate";
import { useHome } from "./homeHooks";
import dayjs from "dayjs";

const getAvailableAmount = (steps: number, receivedCoin?: number) => {
  if (!receivedCoin) {
    return 0;
  }

  const maxCoin = 500;
  let acceptableAmount = 0;
  if (steps >= 3000) acceptableAmount = 100;
  if (steps >= 5000) acceptableAmount = 200;
  if (steps >= 7000) acceptableAmount = 300;
  if (steps >= 10000) acceptableAmount = 500;
  acceptableAmount = acceptableAmount - receivedCoin;

  if (receivedCoin > maxCoin || acceptableAmount < 0) {
    acceptableAmount = 0;
  }
  return acceptableAmount;
};
export interface HomeProps {
  availableCoin: number;
  isReceivingCoin: boolean;
  message: string;
  walk: Walk;
  achievementMissions: StreakStepsMissions[];
  handleReceiveCoin: () => void;
  handleModalOpen: (message: string) => void;
}

export default function Home() {
  const { receivedCoin, ownedCoin, message, achievementMissions, isReceivingCoin, receiveCoin } =
    useHome();

  const [walk, setWalk] = useState<Walk>({
    step: 0,
    calories: 0,
    distance: 0,
    speed: "0",
  });

  useEffect(() => {
    const android = window.android;
    if (android) {
      updateWalk();

      window.updateWalk = () => {
        updateWalk();
      };
    }
  }, []);

  const updateWalk = () => {
    const android = window.android;
    if (android) {
      setWalk({
        step: android.getStep(),
        // step: 10200,
        calories: android.getCalories(),
        distance: android.getDistance(),
        speed: android.getSpeed(),
        date: dayjs().format("YYYYMMDD"),
      });
    }
  };
  //   TextRow {
  //     id: 1395490,
  //     dateKey: '20240220_A002_10',
  //     user_detail_id: 10,
  //     walk_no: null,
  //     walk_cd: 'A002',
  //     walk_ymd: '20240220',
  //     value: '17200',
  //     regdate: 1708569235,
  //     create_date: 2024-02-22T02:33:55.000Z,
  //     update_data: 2024-02-22T02:33:55.000Z
  //   }
  // ]
  if (isReceivingCoin) {
    return <LoadingSection />;
  }

  return (
    <MainTemplate>
      <>
        <TopSection ownedCoin={ownedCoin} />
        <MissionSection
          availableCoin={getAvailableAmount(walk.step, receivedCoin)}
          step={walk.step}
          achievementMissions={achievementMissions}
          onReceiveCoin={receiveCoin}
          isReceivingCoin={isReceivingCoin}
          walkDate={walk?.date}
        />
        <MonitorSection message={message} />
        <WalkSection walk={walk} />
      </>
    </MainTemplate>
  );
}

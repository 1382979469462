import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgHipWorkRange01 from "../../../../styles/assets/img/img-hip-work-range.png";

export const ImgHipWorkRange = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img
            src={ImgHipWorkRange01}
            alt="고관절의 정상적인 운동범위 이미지"
          />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          <S.TxtContentBox>
            입각기 동안에 고관절 가동범위는 0-30도 굴곡, 0-10도 신전하며 유각기
            동안에도 0-30도 굴곡하고 0-10도 신전합니다.
            <br />※ 입각기 말기에서는 엉덩허리근이 길어지면서 원심성조절을
            해야합니다.
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import { EvalLabel, EvalStatus } from "../../EvalLabel";
import * as S from "../walkResultItem.style";

interface WalkResultItemProps {
  title?: string;
  grade?: EvalStatus;
  item?: any;
  children?: React.ReactElement;
  data?: number | string;
  evalData?: number;
  unit?: string;
}

export const ItemColumn = ({
  title,
  grade,
  item,
  children,
  data,
  evalData,
  unit = "mm2",
}: WalkResultItemProps) => {
  return (
    <>
      <S.ItemHead>
        <S.TitBox>{title}</S.TitBox>
        {grade && <EvalLabel status={grade} />}
      </S.ItemHead>
      {children && children}
      {!children && (
        <S.ContentBox>
          {data}
          <S.UnitSpan>{unit}</S.UnitSpan>
        </S.ContentBox>
      )}
    </>
  );
};

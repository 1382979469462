import styled from "styled-components";

export const GaitviewImg = styled.li`
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 280px;
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
`;

import * as S from "./gaitviewImg.style";

interface GaitviewImgProps {
  img: string;
}
export const GaitviewImg = ({ img }: GaitviewImgProps) => {
  return (
    <S.GaitviewImg>
      <img
        src={img && `${process.env.REACT_APP_IMAGE_API}/${img}`}
        alt={img}
        width={280}
        height={280}
      />
    </S.GaitviewImg>
  );
};

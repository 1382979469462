import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import * as S from "./qrcodeScan.style";
import { ReactComponent as IconQrcode } from "../../../styles/assets/svg/icon-qrcode.svg";

export const QRcodeScan = () => {
  const handleButtonClick = () => window.android.qrScan();

  return (
    <S.QRcodeScanBox>
      <Button
        width="100%"
        height={56}
        color="clear"
        fontSize={1.4}
        directColumn={true}
        label="QR 스캔"
        onClick={handleButtonClick}
      >
        <IconQrcode />
      </Button>
    </S.QRcodeScanBox>
  );
};

import { WalkResult } from "../../../pages/Result/Result";
import { EvalStatus } from "../../atoms/EvalLabel";
import { WalkResultItem } from "../../atoms/WalkResultItem";
import * as S from "./walkResultList.style";

interface WalkResultListProps {
  walkResult: WalkResult;
}

export interface Hmt {
  leftHeel?: string;
  rightHeel?: string;
  leftMiddle?: string;
  rightMiddle?: string;
  leftToe?: string;
  rightToe?: string;
}

interface FootPattern {
  left?: string;
  right?: string;
}

function getSpeedGrade(speedRate: string): EvalStatus {
  switch (speedRate) {
    case "1":
      return "worried";
    case "2":
      return "fine";
    case "3":
      return "good";
    default:
      return "fine";
  }
}
function getElevenGrade(eleven: string): { grade: EvalStatus; text: string } {
  switch (eleven) {
    case "1":
      return { grade: "worried", text: "팔자걸음" };
    case "2":
      return { grade: "worried", text: "안짱걸음" };
    case "3":
      return { grade: "good", text: "일반걸음" };
    default:
      return { grade: "good", text: "정상" };
  }
}

function getStanceGrade(left: string = "0", right: string = "0") {
  if (Math.abs(Number(left) - Number(right)) > 10) {
    return "worried";
  }
  return "good";
}

function getHmtGrade(hmt: Hmt) {
  const compareNumber = 5;
  const leftHeel = Number(hmt.leftHeel ?? "0");
  const rightHeel = Number(hmt.rightHeel ?? "0");
  const leftMiddle = Number(hmt.leftMiddle ?? "0");
  const rightMiddle = Number(hmt.rightMiddle ?? "0");
  const leftToe = Number(hmt.leftToe ?? "0");
  const rightToe = Number(hmt.rightToe ?? "0");
  if (leftHeel > 0 && rightHeel > 0) {
    if (leftHeel - rightHeel > compareNumber || rightHeel - leftHeel > compareNumber) {
      return "worried";
    }
    if (leftMiddle - rightMiddle > compareNumber || rightMiddle - leftMiddle > compareNumber) {
      return "worried";
    }
    if (leftToe - rightToe > compareNumber || rightToe - leftToe > compareNumber) {
      return "worried";
    }
  }
  return "good";
}

function getPatternText(data: number): string {
  switch (data) {
    case 1:
      return "일반디딤";
    case 2:
      return "앞쪽디딤";
    case 3:
      return "뒤쪽디딤";
    case 4:
      return "바깥디딤";
    case 5:
      return "안쪽디딤";
    default:
      return "일반디딤";
  }
}

function getPatternResult(pattern: FootPattern): {
  grade: EvalStatus;
  left: string;
  right: string;
} {
  const left = Number(pattern?.left ?? "0");
  const right = Number(pattern?.right ?? "0");
  const grade = left === 0 && right === 0 ? "good" : "worried";
  const leftResult = getPatternText(left);
  const rightResult = getPatternText(right);

  return { grade, left: leftResult, right: rightResult };
}

export const WalkResultList = ({ walkResult }: WalkResultListProps) => {
  const speed = Number.parseFloat(walkResult?.speed ?? "0").toFixed(1);
  const speedGrade = getSpeedGrade(walkResult?.speedRate ?? "0");
  const elevenGrade = getElevenGrade(walkResult?.eleven ?? "0");
  const stance = JSON.parse(walkResult?.stance || "{}");
  const stanceLeft = stance?.left;
  const stanceRight = stance?.right;
  const stanceGrade = getStanceGrade(stanceLeft, stanceRight);
  const pattern = JSON.parse(walkResult?.pattern || "{}");
  const patternResult = getPatternResult(pattern);
  const hmt: Hmt = JSON.parse(walkResult?.hmt || "{}");
  const hmtGrade = getHmtGrade(hmt);
  return (
    <S.WalkResultList>
      {/* {walkResult?.hmt} */}
      <WalkResultItem
        ItemSingle={true}
        title="속도"
        grade={speedGrade}
        item={true}
        data={speed}
        unit="km/h"
      />
      <WalkResultItem
        ItemSingle={true}
        title="발각도"
        grade={elevenGrade.grade}
        item={true}
        data={elevenGrade.text}
        unit={""}
      />
      <S.WalkResultItemGroup>
        <WalkResultItem
          ItemSingle={false}
          title="3박자"
          grade={hmtGrade}
          threeStep={true}
          hmt={hmt}
        />
        <WalkResultItem
          ItemSingle={false}
          title="좌우균형"
          grade={stanceGrade}
          left={stanceLeft}
          right={stanceRight}
        />
        <WalkResultItem
          ItemSingle={false}
          title="지지분포"
          grade={patternResult.grade}
          left={patternResult.left}
          right={patternResult.right}
          unit=""
        />
      </S.WalkResultItemGroup>
    </S.WalkResultList>
  );
};

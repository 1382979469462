import styled, { css } from "styled-components";

interface statsProps {
  status: string;
}

export const EvalLabelBox = styled.div<statsProps>`
  display: flex;
  height: 20px;
  min-width: 47px;
  padding: 0 10px;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 11px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${(props) =>
    props.status === "good" &&
    css`
      background: ${(props) => props.theme.colors.labelGood};
    `}
  ${(props) =>
    props.status === "fine" &&
    css`
      background: ${(props) => props.theme.colors.labelFine};
    `}
      ${(props) =>
    props.status === "worried" &&
    css`
      background: ${(props) => props.theme.colors.labelWorried};
    `}
        ${(props) =>
    props.status === "caution" &&
    css`
      background: ${(props) => props.theme.colors.labelCaution};
    `}
          ${(props) =>
    props.status === "bad" &&
    css`
      background: ${(props) => props.theme.colors.labelBad};
    `}
`;

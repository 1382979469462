import * as S from "./stepGauge.style";
import { StepTick } from "./StepTick";

export const StepGauge = ({ walk }: any) => {
  const tick = [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000];

  const WalkAchieve = (e: number) => {
    if (walk < e) {
      return false;
    }
    return true;
  };

  const WalkGauge = walk * 0.01;

  return (
    <S.StepGaugeBox>
      <S.GaugeBar width={WalkGauge} />
      <S.GaugeTick>
        {tick.map((item: any, idx: number) => {
          return <StepTick active={WalkAchieve(item)} item={item} key={idx} />;
        })}
      </S.GaugeTick>
    </S.StepGaugeBox>
  );
};

import * as S from "./evalComment.style";

interface EvalCommentProps {
  message: string;
}
export const EvalComment = ({ message }: EvalCommentProps) => {
  return (
    <S.EvalCommentBox>
      {message}
      {/* 지난 걸음 속도 기록에 의하면 운동을 효율적으로 하고 있습니다. 다만 걸음
      수가 조금 부족합니다. 건강 유지를 위해 하루에 30분씩 걷기 운동을 즐기세요~
      오늘은 더 힘차게 걸어보시고 건강토큰을 모으는 재미를 느껴보세요! */}
    </S.EvalCommentBox>
  );
};

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgEnv01 from "../../../../styles/assets/img/img-env.png";

export const ImgEnv = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgEnv01} alt="외주면적 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          외주면적은 압력 중심점 궤적의 외피면적을 말하며 단위는 ㎟dlek. 상기
          이미지는 ENV(외주면적)를 묘사하고 있으며 ENV의 면적이 적을수록
          평형성이 좋다는 것을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

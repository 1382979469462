import styled from "styled-components";

export const WalkResultList = styled.ul`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const WalkResultItemGroup = styled.div`
  display: flex;
  padding: 15px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  flex-direction: column;
  grid-column: 1 / 3;
  gap: 20px;
`;

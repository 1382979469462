import { EvalLabel, EvalStatus } from "../EvalLabel";
import * as S from "./walkResultGauge.style";

interface WalkResultGaugeProps {
  title?: string;
  gaugeData?: number;
  grade: EvalStatus;
}

export const WalkResultGauge = ({ title, gaugeData, grade }: WalkResultGaugeProps) => {
  return (
    <S.WalkResultGauge>
      <S.TitBox>
        {title}
        <S.ContentBox>
          {gaugeData}
          <S.UnitSpan>%</S.UnitSpan>
        </S.ContentBox>
      </S.TitBox>
      <S.GaugeBox>
        <S.GaugeBarBox>
          <S.GaugeBar width={gaugeData} />
        </S.GaugeBarBox>

        <EvalLabel status={grade} />
        {/* {grade && <EvalLabel status={grade} />} */}
      </S.GaugeBox>
    </S.WalkResultGauge>
  );
};

import { useSetRecoilState } from "recoil";
import { StreakStepsMissions } from "../../../common/typs";
import { modalSelector } from "../../../states/modal";
import { CountinueMission } from "../../molecules/ContinueMission";
import { DailyMission } from "../../molecules/DailyMission";
import { TokenBox } from "../../molecules/TokenBox";
import * as S from "./missionSection.style";
import dayjs from "dayjs";

interface MissionSectionProps {
  availableCoin: number;
  step: number;
  walkDate?: string;
  isReceivingCoin: boolean;
  achievementMissions: StreakStepsMissions[];
  // onReceiveCoin: () => void;
  onReceiveCoin: Function;
}
function sleep(sec: number) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
} //

export const MissionSection = ({
  availableCoin,
  step,
  isReceivingCoin,
  achievementMissions,
  onReceiveCoin,
  walkDate,
}: MissionSectionProps) => {
  const setModalState = useSetRecoilState(modalSelector);

  const handleModalOpen = (message: string) => {
    setModalState({
      isOpen: true,
      message: message,
    });
  };

  const handleReceiveCoin = async () => {
    try {
      const android = window?.android;
      if (walkDate !== dayjs().format("YYYYMMDD")) {
        alert("날짜가 변경되었습니다. 앱을 재실행 해주세요.");
        return false;
      }

      if (android?.addTodayData) {
        android?.addTodayData();
      }

      sleep(0.5);
      const { msg }: any = await onReceiveCoin({ steps: step });
      alert(msg);
    } catch {
      alert("토큰수령 실패!!");
    }
  };

  return (
    <S.MissionSection>
      <S.MissionBox>
        <DailyMission step={step} onPopup={handleModalOpen} />
        <CountinueMission achievementMissions={achievementMissions} />
      </S.MissionBox>
      <TokenBox
        availableCoin={availableCoin}
        onClick={handleReceiveCoin}
        isReceivingCoin={isReceivingCoin}
      />
    </S.MissionSection>
  );
};

import { Link } from "react-router-dom";
import { ReactComponent as IconArrow } from "../../../styles/assets/svg/icon-arrow-lg.svg";
import * as S from "./monitorBox.style";

interface MonitorBoxProps {
  message: string;
}
export const MonitorBox = ({ message }: MonitorBoxProps) => {
  const goDetail = () => {};
  const handleMoveToEvalHealth = () => window.android?.moveToEvalHealth();
  return (
    <S.MonitorBox>
      <S.TxtBox>{message}</S.TxtBox>
      <S.LinkBox onClick={goDetail}>
        {/* <Link to="/eval-health"> */}
        <div onClick={handleMoveToEvalHealth}>
          나의 건강상태 보기
          <IconArrow />
        </div>
        {/* </Link> */}
      </S.LinkBox>
    </S.MonitorBox>
  );
};

import Chart from "react-apexcharts";
import * as S from "./evalChart.style";
import { WalkingSpeedAvg } from "../../../pages/Eval/eval";

interface EvalChartProps {
  walkingSpeedAvg: WalkingSpeedAvg[];
  sex: "M" | "F";
}
export const EvalChart = ({ walkingSpeedAvg, sex }: EvalChartProps) => {
  return (
    <S.EvalChartBox>
      <S.ChartYaxis>
        <S.ChartYaxisTick className="good">
          <span>우수</span>
        </S.ChartYaxisTick>
        <S.ChartYaxisTick className="fine">
          <span>보통</span>
        </S.ChartYaxisTick>
        <S.ChartYaxisTick className="worried">
          <span>경고</span>
        </S.ChartYaxisTick>
        <S.ChartYaxisTick className="caution">
          <span>위험</span>
        </S.ChartYaxisTick>
        <S.ChartYaxisTick className="bad">
          <span>
            매우
            <br />
            위험
          </span>
        </S.ChartYaxisTick>
      </S.ChartYaxis>
      <Chart
        type="line"
        // width="90%"
        height={280}
        series={[
          {
            name: "나의 걷기평균속도",
            data: walkingSpeedAvg
              .filter((obj) => obj.value !== 0)
              .map((obj) => {
                // return { x: obj.walkYmd, y: (obj.value / 3.6).toFixed(2) };
                return { x: `ㅤ${obj.walkYmd}ㅤ`, y: obj.value.toFixed(2) };
              }),
          },
        ]}
        options={{
          annotations: {
            yaxis: [
              {
                y: "0",
                y2: "2.16",
                fillColor: "#F1B3B7",
              },
              {
                y: "2.16",
                y2: `${sex === "F" ? "2.52" : "2.88"}`,
                fillColor: "#FAD2AB",
              },
              {
                y: `${sex === "F" ? "2.52" : "2.88"}`,
                y2: "3.6",
                fillColor: "#FDEB9B",
              },
              {
                y: "3.6",
                y2: `${sex === "F" ? "4.5" : "4.68"}`,
                fillColor: "#C4EACC",
              },
              {
                y: `${sex === "F" ? "4.5" : "4.68"}`,
                y2: "6",
                fillColor: "#AEB9F4",
              },
            ],
          },
          chart: {
            redrawOnWindowResize: true,
            toolbar: {
              show: false,
            },
          },
          // grid: {
          //   borderColor: "#C4C4C4",
          //   row: {
          //     colors: ["#AEB9F4", "#C4EACC", "#FDEB9B", "#FAD2AB", "#F1B3B7"],
          //   },
          //   padding: {
          //     top: 0,
          //     right: 10,
          //     bottom: 0,
          //     left: 30,
          //   },
          // },
          stroke: {
            width: 2,
            colors: ["#000000"],
          },
          // markers: {
          //   size: 1,
          //   colors: ["#000000"],
          // },
          tooltip: {
            enabled: false,
          },
          xaxis: {
            offsetX: 20,
            labels: {
              hideOverlappingLabels: true,
              rotate: 0,
            },
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              show: false,
            },
            min: 0,
            max: 6,
          },
        }}
      >
        <S.ChartYaxis>
          {/* <S.ChartYaxisTick>좋음</S.ChartYaxisTick>
          <S.ChartYaxisTick>보통</S.ChartYaxisTick>
          <S.ChartYaxisTick>경고</S.ChartYaxisTick>
          <S.ChartYaxisTick>위험</S.ChartYaxisTick>
          <S.ChartYaxisTick>
            매우
            <br />
            위험
          </S.ChartYaxisTick> */}
        </S.ChartYaxis>
      </Chart>
    </S.EvalChartBox>
  );
};

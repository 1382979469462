import * as S from "./tokenStatus.style";
import { QRcodeScan } from "../../atoms/QRcodeScan";
import { TokenWallet } from "../../atoms/TokenWallet";

interface TokenStatusProps {
  ownedCoin: number;
}
export const TokenStatus = ({ ownedCoin }: TokenStatusProps) => {
  return (
    <S.TokenStatusBox>
      <TokenWallet ownedCoin={ownedCoin} />
      <QRcodeScan />
    </S.TokenStatusBox>
  );
};

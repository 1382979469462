import styled from "styled-components";

export const PhysicResultSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 700;
  align-items: center;
  gap: 10px;
`;

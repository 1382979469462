import styled from "styled-components";

export const StepSideBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const StepSideItem = styled.div`
  display: flex;
  width: 58px;
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  gap: 1px;
`;

export const StepSideUnit = styled.span`
  color: ${(props) => props.theme.colors.unitTxt};
  font-weight: 500;
`;

import styled from "styled-components";

export const EvalChartBox = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 10px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.chartOuterBorder};
  border-radius: 10px;
`;

export const ChartYaxis = styled.div`
  position: absolute;
  display: flex;
  width: calc(90% + 5px);
  margin-top: 30px;
  align-items: flex-end;
  flex-direction: column;
`;

export const ChartYaxisTick = styled.span`
  display: flex;
  align-items: center;

  &.good {
    height: 48px;

    span {
      background: ${(props) => props.theme.colors.chartLabelGood};
    }
  }

  &.fine {
    height: 39.2px;

    span {
      background: ${(props) => props.theme.colors.chartLabelFine};
    }
  }

  &.worried {
    height: 26.1px;

    span {
      background: ${(props) => props.theme.colors.chartLabelWorried};
    }
  }

  &.caution {
    height: 26.1px;

    span {
      background: ${(props) => props.theme.colors.chartLabelCaution};
    }
  }

  &.bad {
    height: 78.5px;

    span {
      background: ${(props) => props.theme.colors.chartLabelBad};
    }
  }

  span {
    display: flex;
    padding: 3px 4px;
    color: ${(props) => props.theme.colors.chartLabelTxt};
    border: 1px solid ${(props) => props.theme.colors.chartLabelTxt};
    border-radius: 3px;
    font-size: 1rem;
  }
`;

import styled from "styled-components";

export const TokenBox = styled.div`
  display: grid;
  width: 100%;
  border-radius: 10px;
  grid-template-columns: auto 90px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};

  button {
    border-radius: 0 10px 10px 0;
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
  }
`;

export const TokenSavingBox = styled.div`
  display: flex;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px 0 0 10px;
  align-items: center;
  justify-content: space-between;
`;

export const TitBox = styled.div`
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  align-items: center;
  gap: 7px;
`;

export const TokenStatusBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
  gap: 5px;
`;

export const StatusSpan = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-size: 2.2rem;
  font-weight: 800;
`;

import styled from "styled-components";

export const UseInfoList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const UseInfoTipBox = styled.div`
  padding: 10px 0;
  color: ${(props) => props.theme.colors.tipTxt};
  font-size: 1.3rem;
  text-align: center;
`;

import { useQuery } from "@tanstack/react-query";
import { QnATemplate } from "../../components/templates/QnATemplate";
import styled from "styled-components";
import { QNA_LIST } from "../../common/api-codes";
import { request } from "../../lib/api";
import dayjs from "dayjs";
import { LoadingSection } from "../../components/organisms/LoadingSection";
import QnAModal from "./QnAModal";
import { useState } from "react";

const Body = styled.div``;

const QnAList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const QnAItem = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
`;

const QnAItemTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const QnAItemContent = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const QnAItemDate = styled.div`
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #999;
`;

const AnswerBox = styled.div`
  margin-top: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const QATitle = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
`;

const Count = styled.span`
  font-size: 1.25rem;
  margin-left: 10px;
  color: #f19022;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background-color: #f19022;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
`;

export type UserQnAType = "pending" | "answered" | "closed";

export interface UserQnA {
  id: number;
  authorId: number;
  responderId: number;
  question: string;
  answer?: string;
  questionTime: Date;
  answerTime?: Date;
  status: UserQnAType;
}

export default function QnA() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: qnaList, isLoading } = useQuery([QNA_LIST], () =>
    request<UserQnA[]>({
      method: "GET",
      url: `api/v1/qna/mobile`,
    }).then((res) => {
      return res;
    })
  );

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && <QnAModal onClose={handleModalClose} />}
      <QnATemplate>
        <>
          <Body>
            <HeaderContainer>
              <TitleContainer>
                <QATitle>Q&A</QATitle>
                <Count>{qnaList?.length}개</Count>
              </TitleContainer>
              <ButtonContainer>
                <Button onClick={handleModalOpen}>질문 작성하기</Button>
              </ButtonContainer>
            </HeaderContainer>
            {isLoading ? (
              <LoadingSection />
            ) : (
              <QnAList>
                {qnaList?.map((qna) => {
                  return (
                    <QnAItem key={qna.id}>
                      <div>
                        <QnAItemTitle>Q. 질문</QnAItemTitle>
                        <QnAItemContent>{qna.question}</QnAItemContent>
                        <QnAItemDate>
                          {dayjs(qna.questionTime).format("YYYY.MM.DD HH:mm:ss")}
                        </QnAItemDate>
                        {qna.answer && (
                          <AnswerBox>
                            <QnAItemTitle>A. 답변</QnAItemTitle>
                            <QnAItemContent>{qna.answer}</QnAItemContent>
                            <QnAItemDate>
                              {dayjs(qna.answerTime).format("YYYY.MM.DD HH:mm:ss")}
                            </QnAItemDate>
                          </AnswerBox>
                        )}
                      </div>
                    </QnAItem>
                  );
                })}
              </QnAList>
            )}
          </Body>
        </>
      </QnATemplate>
    </>
  );
}

import styled from "styled-components";

export const MonitorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TxtBox = styled.div`
  padding: 20px;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: -0.4px;
  word-break: keep-all;
  border-radius: 10px 10px 0 0;
  background: ${(props) => props.theme.colors.white};
`;

export const LinkBox = styled.div`
  cursor: pointer;

  div {
    display: flex;
    padding: 14px 20px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: 0 0 10px 10px;
    background: ${(props) => props.theme.colors.white};
    align-items: center;
    justify-content: space-between;
  }
`;

import styled from "styled-components";

export const LoadignBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.subTxt};
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgHipRotation01 from "../../../../styles/assets/img/img-hip-rotation-01.png";
import ImgHipRotation02 from "../../../../styles/assets/img/img-hip-rotation-02.png";
import ImgHipRotation03 from "../../../../styles/assets/img/img-hip-rotation-03.png";

export const ImgHipRotation = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgHipRotation01} alt="고관절 회전 이미지-01" />
          <img src={ImgHipRotation02} alt="고관절 회전 이미지-02" />
          <img src={ImgHipRotation03} alt="고관절 회전 이미지-03" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          <S.SubtitBox>• 과도한 고관절 외회전의 원인</S.SubtitBox>
          <S.TxtContentBox>
            <S.TxtGrid>
              <S.TxtTitBox>- 부하 반응기와 중간 입각기</S.TxtTitBox>
              외회전 자세 구축
              <br />
              족관절 배굴운동의 제한
            </S.TxtGrid>
          </S.TxtContentBox>
          <S.TxtContentBox>
            <S.TxtGrid>
              <S.TxtTitBox>- 유각기</S.TxtTitBox>
              고관절 굴근군의 근력부족에 관계없이 다리를 전방으로 운반하기 위한
              의도적인 운동
              <br />
              Toe Clearance를 얻기 위한 의도적인 운동
            </S.TxtGrid>
          </S.TxtContentBox>
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 과도한 외회전이 보행의 기전에 미치는 영향</S.SubtitBox>
          <S.TxtContentBox>
            <S.TxtGrid>
              <S.TxtTitBox>- 반응기와 중간 입각기</S.TxtTitBox>
              외측으로 비틀린 발(Toe out)의 위치가 지지면을 증대시켜 전족부
              흔들지레 기능을 저해
              <br />
              신체중량을 전방으로 운반할 때 내측 슬관절 구성체에 부하 증대
            </S.TxtGrid>
          </S.TxtContentBox>
          <S.TxtContentBox>
            <S.TxtGrid>
              <S.TxtTitBox>- 유각기</S.TxtTitBox>
              Toe Clearance를 얻기 쉽게 함
            </S.TxtGrid>
          </S.TxtContentBox>
        </S.ContentBox>

        {/* 이미지 설명-3 */}
        <S.ContentBox>
          <S.SubtitBox>• 고관절 회전의 정상범위</S.SubtitBox>
          <S.TxtContentBox>
            보행시 정상적인 고관절의 회전운동은 각 방향으로 5도 이내 이므로
            관찰이 어려움.
          </S.TxtContentBox>
        </S.ContentBox>

        {/* 이미지 설명-4 */}
        <S.ContentBox>
          <S.SubtitBox>• 과도한 고관절 내회전의 원인</S.SubtitBox>
          <S.TxtContentBox>
            내회전근의 과도한 활동 또는 구축
            <br />
            대퇴골의 전염(Anterior Torsion)
            <br />
            대퇴사두근의 근력부족인 경우 슬관절의 안정성을 개선하기 위한 의도적
            운동
          </S.TxtContentBox>
        </S.ContentBox>

        {/* 이미지 설명-5 */}
        <S.ContentBox>
          <S.SubtitBox>
            • 과도한 고관절 내회전이 보행 기전에 미치는 영향
          </S.SubtitBox>
          <S.TxtContentBox>
            내측으로 비틀린 발의 위치(Toe-in)가 다리의 기능적 변화를 일으켜 전방
            움직임과 바닥에서 발을 떼는 동작이 저해됨
            <br />
            부하 반응기와 중간 입각기 동안 슬관절의 외측 부하
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

import { Hmt } from "../../molecules/WalkResultList";
import * as S from "./walkThreeStep.style";

interface ThreeStepProps {
  stepSide?: string;
  hmt?: Hmt;
}

export const WalkThreeStep = ({ stepSide, hmt }: ThreeStepProps) => {
  const heel = stepSide === "L" ? hmt?.leftHeel : hmt?.rightHeel;
  const middle = stepSide === "L" ? hmt?.leftMiddle : hmt?.rightMiddle;
  const toe = stepSide === "L" ? hmt?.leftToe : hmt?.rightToe;
  return (
    <S.WalkThreeStep>
      <S.StepSideTit>{stepSide}</S.StepSideTit>
      <S.StepSideBox>
        <S.StepSideSpan stepSide={stepSide} stepLocate="top">
          {toe ?? "0"}
          <S.StepSideUnit>%</S.StepSideUnit>
        </S.StepSideSpan>
        <S.StepSideSpan stepSide={stepSide} stepLocate="middle">
          {middle ?? "0"}
          <S.StepSideUnit>%</S.StepSideUnit>
        </S.StepSideSpan>
        <S.StepSideSpan stepSide={stepSide} stepLocate="bottom">
          {heel ?? "0"}
          <S.StepSideUnit>%</S.StepSideUnit>
        </S.StepSideSpan>
      </S.StepSideBox>
    </S.WalkThreeStep>
  );
};

import styled from "styled-components";

export const CountinueMissionBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export const TitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitSpan = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.4rem;
  font-weight: 600;
  align-items: center;
  gap: 5px;

  svg {
    &:last-child {
      margin-left: 2px;
    }
  }
`;

export const ChearTxtBox = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.shadeTxt};
  font-size: 1.2rem;
  font-weight: 600;
  align-items: center;
`;

export const ChearTxtSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 800;
`;

export const MissionList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
`;

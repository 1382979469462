import * as S from "./resultModalImg.style";

export interface ResultModalImgProps {
  title?: string;
  children?: React.ReactElement;
}

export const ResultModalImg = ({ title, children }: ResultModalImgProps) => {
  return (
    <S.ResultModalImg>
      {/* 이미지 타이틀 */}
      <S.TitBox>{title}</S.TitBox>
      {children}
    </S.ResultModalImg>
  );
};

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgFactorBackFront01 from "../../../../styles/assets/img/img-factor-backfront.png";

export const ImgFactorBackFront = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgFactorBackFront01} alt="몸통 전방 기울임 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          1. 대퇴사두근의 근력이 부족할 때
          <br />
          2. 발목관절의 가동성이 없을 때
          <br />
          3. 몸통 신전 운동의 가동범위가 제한될 때
          <br />
          4. 부하 반응기(LR)와 입각중기(MSt)에서 과도하게 고관절이 굴곡될 때
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 몸통 전방 기울기가 보행주기에 미치는 영향</S.SubtitBox>
          <S.TxtContentBox>
            1. 고관절과 몸통의 신전근군의 작용이 크게 요구됩니다.
          </S.TxtContentBox>
          <S.TxtContentBox>2. 등 통증의 원인이 될 수 있습니다.</S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

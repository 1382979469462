import { WalkResult } from "../../../pages/Result/Result";
import { WalkResultList } from "../../molecules/WalkResultList";
import * as S from "./walkResultSection.style";

interface WalkResultSectionProps {
  walkResult: WalkResult;
}
export const WalkResultSection = ({ walkResult }: WalkResultSectionProps) => {
  return (
    <S.WalkResultSection>
      <S.TitBox>나의 보행분석결과</S.TitBox>
      <WalkResultList walkResult={walkResult} />
    </S.WalkResultSection>
  );
};

import styled from "styled-components";

interface GaugeProp {
  width: number;
}

export const StepGaugeBox = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 11px);
  height: 30px;
  padding: 5px;
  background: ${(props) => props.theme.colors.shadeBg};
  border-radius: 15px;
  align-items: center;
  justify-content: flex-start;
`;

export const GaugeTick = styled.ul`
  position: absolute;
  display: flex;
  width: calc(100% - 10px);
  height: 30px;
  align-items: center;
  justify-content: space-between;
`;

export const GaugeBar = styled.div<GaugeProp>`
  width: ${(props) => props.width + "%"};
  height: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.gradient.gauge};
`;

import * as S from "../resultModalImg.style";
import ImgKneeWork01 from "../../../../styles/assets/img/img-knee-work.png";
import { ResultModalImg, ResultModalImgProps } from "..";

export const ImgKneeWork = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgKneeWork01} alt="무릎관절에서의 정상적인 운동범위" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          입각기 동안의 무릎관절 가동범위는 0-15도 이고 유각기에는 0-60도
          입니다.
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 중간 입각기</S.SubtitBox>
          <S.TxtContentBox>
            무릎관절이 5도 정도 굽힘됨으로 넙다리네근갈래근들이 원심성 조절을
            합니다.
          </S.TxtContentBox>
        </S.ContentBox>

        {/* 이미지 설명-3 */}
        <S.ContentBox>
          <S.SubtitBox>• 말기 유각기</S.SubtitBox>
          <S.TxtContentBox>
            뒤넙다리근군이 원심성 조절을 합니다.
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

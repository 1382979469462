import * as S from "./walkItem.style";
import { ReactComponent as IconWalk } from "../../../styles/assets/svg/icon-walkcount.svg";
import { ReactComponent as IconDistance } from "../../../styles/assets/svg/icon-distance.svg";
import { ReactComponent as IconSpeed } from "../../../styles/assets/svg/icon-speed.svg";
import { ReactComponent as IconCalories } from "../../../styles/assets/svg/icon-calories.svg";

export const WalkItem = ({ item, name }: any) => {
  const data = [
    {
      name: "step",
      title: "걸음수",
      unit: "걸음",
    },
    {
      name: "distance",
      title: "이동거리",
      unit: "km",
    },
    {
      name: "speed",
      title: "평균속도",
      unit: "km/h",
    },
    {
      name: "calories",
      title: "소모칼로리",
      unit: "kcal",
    },
  ];

  const ItemArray = data.find(function (item: any) {
    return item.name === name;
  });

  return (
    <S.WalkItem>
      <S.TitBox>
        {name === "step" && <IconWalk width={34} height={34} />}
        {name === "distance" && <IconDistance width={34} height={34} />}
        {name === "speed" && <IconSpeed width={34} height={34} />}
        {name === "calories" && <IconCalories width={34} height={34} />}

        {ItemArray?.title}
      </S.TitBox>
      <S.StatusBox>
        {item}
        <S.UnitSpan>{ItemArray?.unit}</S.UnitSpan>
      </S.StatusBox>
    </S.WalkItem>
  );
};

import React, { memo } from "react";
import { MonitorBox } from "../../molecules/MonitorBox";
import * as S from "./monitorSection.style";

interface MonitorSectionProps {
  message: string;
}
const MonitorSection = ({ message }: MonitorSectionProps) => {
  return (
    <S.MonitorSection>
      <S.TitBox>건강상태 모니터링</S.TitBox>
      <MonitorBox message={message} />
    </S.MonitorSection>
  );
};

export default memo(MonitorSection);

import { ExbodyResultList } from "../../molecules/ExbodyResultList";
import * as S from "./exbodySection.style";

export const ExbodySection = ({ surveyResult }: any) => {
  return (
    <S.ExbodySection>
      <S.TitBox>EXBODY 분석결과</S.TitBox>
      <ExbodyResultList surveyResult={surveyResult} />
    </S.ExbodySection>
  );
};

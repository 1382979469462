"react";
import { EvalLabel, EvalStatus } from "../../atoms/EvalLabel";
import { EvalComment } from "../../molecules/EvalComment";
import * as S from "./evalCommentSection.style";

interface EvalCommentSectionProps {
  message: string;
  grade: EvalStatus | undefined;
}

export const EvalCommentSection = ({ message, grade }: EvalCommentSectionProps) => {
  return (
    <S.EvalCommentSection>
      <S.TitBox>나의 건강상태 평가 {grade && <EvalLabel status={grade} />}</S.TitBox>
      <EvalComment message={message} />
    </S.EvalCommentSection>
  );
};

import styled from "styled-components";

export const GaitResultItem = styled.li`
  display: flex;
  width: 100%;
  padding: 15px 0;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  flex-direction: column;
`;

export const ItemBox = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 15px;
  align-items: center;
  justify-content: space-between;
`;

export const ItemRowGroup = styled.div`
  display: flex;
  width: 100%;

  ${ItemBox} {
    + ${ItemBox} {
      border-left: 1px solid ${(props) => props.theme.colors.noticeBorder};
    }
  }
`;

export const ItemTit = styled.div`
  display: flex;
  height: 24px;
  padding: 0 4px;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.5em;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1px;

  &:active {
    background: ${(props) => props.theme.colors.shadeBg};
    border-radius: 4px;
  }

  span {
    display: flex;
    font-size: 1.4rem;
    border-radius: 4px;
  }
`;

export const ItemContentBox = styled.div`
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 3px;
`;

export const ItemUnit = styled.div`
  color: ${(props) => props.theme.colors.unitTxt};
  font-weight: 500;
`;

import { LoadingSpinner } from "../../atoms/LoadingSpinner";
import * as S from "./loadingBox.style";

export const LoadingBox = ({ text }: any) => {
  return (
    <S.LoadignBox>
      <LoadingSpinner />
      {text}
    </S.LoadignBox>
  );
};

import { useState } from "react";
import { EvalChart } from "../../molecules/EvalChart";
import { SelectOptions } from "../../molecules/SelectOptions";
import * as S from "./evalChartSection.style";
import { WalkingSpeedAvg } from "../../../pages/Eval/eval";

interface EvalChartSectionProps {
  walkingSpeedAvg: WalkingSpeedAvg[];
  period: "daily" | "week" | "month";
  sex: "M" | "F";
  setPeriod: Function;
}

export const EvalChartSection = ({
  walkingSpeedAvg,
  sex,
  period,
  setPeriod,
}: EvalChartSectionProps) => {
  const options = [
    {
      id: "daily",
      name: "일간",
    },
    {
      id: "week",
      name: "주간",
    },
    {
      id: "month",
      name: "월간",
    },
  ];

  return (
    <S.EvalChartSection>
      <S.TitBox>나의 걷기평균속도</S.TitBox>
      <SelectOptions
        bgTheme="light"
        itemCount={3}
        options={options}
        currentOption={period}
        setData={setPeriod}
      />
      <EvalChart walkingSpeedAvg={walkingSpeedAvg} sex={sex} />
    </S.EvalChartSection>
  );
};

import { useQuery } from "@tanstack/react-query";
import { NoticeListSection } from "../../components/organisms/NoticeListSection";
import { NoticeTemplate } from "../../components/templates/NoticeTemplate";
import { FCM_LIST } from "../../common/api-codes";
import { request } from "../../lib/api";

export type NotificationType = "notice" | "userFcm";

export interface Notification {
  id: number;
  type: NotificationType;
  title: string;
  message: string;
  createdAt: Date;
}

export interface UserNotification {
  notice: Notification[];
  userFcm: Notification[];
}

export default function Notice() {
  const { data, isLoading } = useQuery([FCM_LIST], () =>
    request<{ userNotification: UserNotification }>({
      method: "GET",
      url: `api/v1/notification/fcm-list`,
    }).then((res) => {
      return res.userNotification;
    })
  );

  console.log(data);

  return (
    <NoticeTemplate>
      <NoticeListSection
        userNotification={data ?? { notice: [], userFcm: [] }}
        isLoading={isLoading}
      />
    </NoticeTemplate>
  );
}

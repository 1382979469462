import styled from "styled-components";

export const DailyMissionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitSpan = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  gap: 5px;
`;

export const StepBox = styled.div`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  gap: 5px;
`;

export const StepSpan = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-size: 2.6rem;
  font-weight: 800;
`;

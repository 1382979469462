import { LoadingBox } from "../../molecules/LoadingBox";
import * as S from "./loadingSection.style";

export const LoadingSection = () => {
  return (
    <S.LoadingSection>
      <LoadingBox text="데이터를 불러오는 중..." />
    </S.LoadingSection>
  );
};

import * as S from "./useInfoItem.style";
import { ReactComponent as IconMapPin } from "../../../styles/assets/svg/icon-mapPin.svg";
import { UseInfo } from "../../../pages/UseInfo/UseInfo";

interface UseInfoItemProps {
  useInfo: UseInfo;
}

export const UseInfoItem = ({ useInfo }: UseInfoItemProps) => {
  return (
    <S.UseInfoItem>
      <S.UseInfoTitSpan>
        <div style={{ display: "flex" }}>
          <IconMapPin width={20} height={20} />
          <S.UseInfoName>{useInfo.name}</S.UseInfoName>
        </div>

        <S.UseInfoTxtNumber>{useInfo.phone_number}</S.UseInfoTxtNumber>
      </S.UseInfoTitSpan>

      <S.UseInfoTxtBox>{useInfo.address}</S.UseInfoTxtBox>
    </S.UseInfoItem>
  );
};

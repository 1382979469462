import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  gradient: {
    gauge: "linear-gradient(90deg, #FFCB00 0%, #C2CB35 21.87%, #6BCB80 100%)",
  },
  shadows: {
    boxShadow: "0 2px 10px -5px rgba(0,0,0,.1)",
    cardShadow: "0 3px 15px -4px rgba(0,0,0,.14)",
  },
  colors: {
    primary: "#F17E0B",

    mainBg: "#F5F5F5",
    shadeBg: "#F5F5F5",

    mainTxt: "#393635",
    subTxt: "#959595",
    shadeTxt: "#787878",
    tipTxt: "#A7A7A7",
    chartLabelTxt: "#333333",
    unitTxt: "#707070",

    noticeBorder: "#DBDBDB",
    chartOuterBorder: "#787878",
    chartInnerBorder: "#C4C4C4",

    active: "#FFC400",
    inactive: "#C4C4C4",

    labelGood: "#3551E2",
    labelFine: "#6BCB80",
    labelWorried: "#FBCC05",
    labelCaution: "#F38F2C",
    labelBad: "#DC424B",

    chartLabelGood: "#aeb9f4",
    chartLabelFine: "#c4eacc",
    chartLabelWorried: "#fdeb9b",
    chartLabelCaution: "#fad2ab",
    chartLabelBad: "#f1b3b7",

    black: "#000000",
    white: "#ffffff",
  },
};

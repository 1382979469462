import { SurveyResult } from "../../../pages/Result/Result";
import { GaitviewResultList } from "../../molecules/GaitviewResultList";
import * as S from "./gaitviewSection.styled";

export const GaitviewSection = ({ surveyResult }: any) => {
  return (
    <S.GaitviewSection>
      <S.TitBox>GAITVIEW 분석결과</S.TitBox>
      <GaitviewResultList surveyResult={surveyResult} />
    </S.GaitviewSection>
  );
};

import React, { memo } from "react";
import { WalkStatus } from "../../molecules/WalkStatus";
import * as S from "./walkSection.style";
import { ReactComponent as IconArrow } from "../../../styles/assets/svg/icon-arrow-sm.svg";

const WalkSection = ({ walk }: any) => {
  const handleMoveToWalkDetail = () => window.android.moveToWalkDetail();

  return (
    <S.WalkSection>
      <S.TitBox onClick={handleMoveToWalkDetail}>
        나의 워킹정보
        <IconArrow width={8} height={20} />
      </S.TitBox>
      <WalkStatus data={walk} />
    </S.WalkSection>
  );
};

export default memo(WalkSection);

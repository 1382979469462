import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { MESSAGE_HOME, WALKING_SPEED_AVG } from "../../common/api-codes";
import { EvalChartSection } from "../../components/organisms/EvalChartSection";
import { EvalTemplate } from "../../components/templates/EvalTemplate";
import { request } from "../../lib/api";
import { MessageResponse } from "../Home/homeHooks";
import { EvalCommentSection } from "../../components/organisms/EvalCommentSection";

export interface WalkingSpeedAvg {
  walkYmd: string;
  value: number;
}

export default function Eval() {
  const [period, setPeriod] = useState<"daily" | "week" | "month">("daily");
  const [sex, setSex] = useState<"M" | "F">("M");
  const { data } = useQuery([WALKING_SPEED_AVG, period], () =>
    request<{ walkingSpeedAvg: WalkingSpeedAvg[] }>({
      method: "GET",
      url: `api/v1/walk/walking-speed-avg/${period}`,
    }).then((res) => {
      return res.walkingSpeedAvg ?? [];
    })
  );

  const { data: messageResponse, isLoading: messageLoading } = useQuery(
    [MESSAGE_HOME],
    () =>
      request<MessageResponse>({ method: "GET", url: `api/v1/message` }).then(
        (res) => res
      )
  );

  useEffect(() => {
    const android = window.android;
    if (android) {
      setSex(android.getSex());
    }
  }, []);

  return (
    <EvalTemplate>
      <>
        <EvalChartSection
          sex={sex}
          walkingSpeedAvg={data ?? []}
          period={period}
          setPeriod={setPeriod}
        />
        {!messageLoading && (
          <EvalCommentSection
            message={messageResponse?.message ?? ""}
            grade={messageResponse?.grade}
          />
        )}
      </>
    </EvalTemplate>
  );
}

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgRec01 from "../../../../styles/assets/img/img-rec.png";

export const ImgRec = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgRec01} alt="단형면적 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          단형면적은 각 프레임별 COP에서 최대 왼쪽, 오른쪽, 위쪽, 아래쪽의
          위치를 통해 사격 면적을 구한 것을 말하며 단위는 ㎟이다. 상기 이미지는
          REC(단형면적)를 묘사하고 있다. REC 역시 면적이 적을수록 평형성이 더
          좋다는 것을 의미한다.
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

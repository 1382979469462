import { Hmt } from "../../../molecules/WalkResultList";
import { EvalLabel, EvalStatus } from "../../EvalLabel";
import { WalkThreeStep } from "../../WalkThreeStep";
import * as S from "../walkResultItem.style";
import * as T from "./itemRow.style";

interface WalkResultItemProps {
  title?: string;
  grade?: EvalStatus;
  item?: any;
  ItemSingle?: boolean;
  threeStep?: boolean;
  children?: React.ReactElement;
  left: string;
  right: string;
  unit?: string;
  hmt?: Hmt;
}

export const ItemRow = ({
  title,
  grade,
  threeStep,
  children,
  left,
  right,
  unit = "%",
  hmt,
}: WalkResultItemProps) => {
  return (
    <>
      <S.TitBox>{title}</S.TitBox>
      <T.StepSideBox>
        <T.StepSideItem>
          {threeStep && <WalkThreeStep stepSide="L" hmt={hmt} />}
          {!threeStep && (
            <>
              {left}
              <T.StepSideUnit>{unit}</T.StepSideUnit>
            </>
          )}
        </T.StepSideItem>
        <T.StepSideItem>
          {threeStep && <WalkThreeStep stepSide="R" hmt={hmt} />}
          {!threeStep && (
            <>
              {right}
              <T.StepSideUnit>{unit}</T.StepSideUnit>
            </>
          )}
        </T.StepSideItem>
      </T.StepSideBox>
      {grade && <EvalLabel status={grade} />}
    </>
  );
};

import normalize from "styled-normalize";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
${normalize}

* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

html, body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  letter-spacing: -0.5px;
  font-family: 'NanumSquare', sans-serif
}

ul, li {
padding: 0;
margin: 0;
list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

.light {
  font-weight: 300
}

.normal {
  font-weight: 400
}

.bold {
  font-weight: 700
}

.bolder {
  font-weight: 800
}
`;

import * as S from "./noticeItem.style";
import { ReactComponent as IconNotice } from "../../../styles/assets/svg/icon-notice.svg";
import { Notification } from "../../../pages/Notice/Notice";
import dayjs from "dayjs";
import { notificationType } from "../../../common/object";

interface NoticeItemProps {
  notification: Notification;
}

export const NoticeItem = ({ notification }: NoticeItemProps) => {
  return (
    <S.NoticeItem>
      <S.NoticeTitSpan>
        <IconNotice width={28} height={28} />
        {notificationType[notification.type]}
      </S.NoticeTitSpan>
      <S.NoticeTxtBox>{notification.title}</S.NoticeTxtBox>
      <S.NoticeTxtBox>{notification.message}</S.NoticeTxtBox>
      <S.NoticeDateBox>{dayjs(notification.createdAt).format("YYYY-MM-DD")}</S.NoticeDateBox>
    </S.NoticeItem>
  );
};

import { ResultTemplate } from "../../components/templates/ResultTemplate";
import { WalkResultSection } from "../../components/organisms/WalkResultSection";
import { PhysicResultSection } from "../../components/organisms/PhysicResultSection";
import { GaitviewSection } from "../../components/organisms/GaitviewSection";
import { ExbodySection } from "../../components/organisms/ExbodySection";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { SURVEY_RESULT } from "../../common/api-codes";
import { request } from "../../lib/api";
import { AxiosError } from "axios";
import { LoadingSection } from "../../components/organisms/LoadingSection";
import * as S from "./result.style";
import { useEffect, useState } from "react";

export interface SurveyResult {
  L_gripstrength_average: number;
  R_gripstrength_average: number;
  filename: string;
  pat_bend: number;
  pat_eightwalk: number;
  pat_eightwalk_eval: number;
  pat_env: number;
  pat_kneejoint_extension: number;
  pat_length_env: number;
  pat_lowflexion: number;
  pat_name: string;
  pat_num: number;
  pat_numberofstep: number;
  pat_age: number;
  pat_rec: number;
  pat_rms: number;
  pat_sit_n_up: number;
  pat_sit_n_up_eval: number;
  pat_standing_highjump: number;
  pat_standing_highjump_eval: number;
  pat_step_length: number;
  pat_stride_length: number;
  pat_sway_velccity: number;
  pat_tlc: number;
  pat_total_length: number;
  pat_walking_speed: number;
  result: number;
  is_highjump: number;
  old?: number;
  young?: number;
}

export interface WalkResult {
  speed?: string;
  eleven?: string;
  stance?: string;
  hmt?: string;
  pattern?: string;
  speedRate?: string;
}

export default function Result() {
  const [walkResult, setWalkResult] = useState({});
  /** result 데이터 */
  const { data: surveyResult, isLoading } = useQuery<SurveyResult, AxiosError>(
    [SURVEY_RESULT],
    () =>
      request<SurveyResult>({
        method: "GET",
        url: `api/v1/result`,
      }).then((res) => res)
  );

  useEffect(() => {
    const android = window.android;
    if (android) {
      const result = android.getResult();
      setWalkResult(JSON.parse(result));
    }
  }, []);
  return (
    <ResultTemplate>
      <>
        <WalkResultSection walkResult={walkResult} />
        {isLoading ? (
          <LoadingSection />
        ) : // 나이가 65 이상이면서 결과보기에 해당하는 값이 모두 있을때
        (surveyResult && surveyResult?.old) ||
          // 나이가 65세이하이면서( 결과보기 값에 제자리 높이뛰기값 추가됨 ) 결과보기에 해당하는 값 + 제자리 높이뛰기 값이 있을때
          (surveyResult && surveyResult?.young) ? (
          <>
            <PhysicResultSection surveyResult={surveyResult} />
            <GaitviewSection surveyResult={surveyResult} />
            <ExbodySection surveyResult={surveyResult} />
          </>
        ) : (
          <S.TitBox>설문이 완료되지 않았습니다.</S.TitBox>
        )}
      </>
    </ResultTemplate>
  );
}

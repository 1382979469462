import { Hmt } from "../../../molecules/WalkResultList";
import { EvalLabel, EvalStatus } from "../../EvalLabel";
import { WalkThreeStep } from "../../WalkThreeStep";
import * as S from "../walkResultItem.style";
import * as T from "./itemRow.style";
import { ReactComponent as IconInfo } from "../../../../styles/assets/svg/icon-info.svg";

interface WalkResultItemProps {
  title?: string;
  grade?: EvalStatus;
  item?: any;
  ItemSingle?: boolean;
  threeStep?: boolean;
  children?: React.ReactElement;
  unit?: string;
  left?: string;
  right?: string;
  leftTop?: string;
  leftBottom?: string;
  rightTop?: string;
  rightBottom?: string;
  subTitLT?: string;
  subTitLB?: string;
  subTitRT?: string;
  subTitRB?: string;
  onClick?: any;
}

export const ExbodyItemTwoRow = ({
  title,
  grade,
  children,
  unit = "%",
  left,
  leftTop,
  leftBottom,
  rightTop,
  rightBottom,
  subTitLT,
  subTitLB,
  subTitRT,
  subTitRB,
  onClick,
}: WalkResultItemProps) => {
  return (
    <>
      <S.TitBox onClick={onClick}>
        <span>
          {title}
          <IconInfo />
        </span>
      </S.TitBox>
      <T.StepSideBox>
        {/* 첫번째 줄 */}
        <T.RowWrapper>
          <T.DirectionTit>오른쪽</T.DirectionTit>
          <T.StepSideItem>
            <div>{subTitLT} : </div>
            {leftTop}
            <T.StepSideUnit>{unit}</T.StepSideUnit>
          </T.StepSideItem>
          <T.StepSideItem>
            <div>{subTitRT} : </div>
            {rightTop}
            <T.StepSideUnit>{unit}</T.StepSideUnit>
          </T.StepSideItem>
        </T.RowWrapper>
        {/* 두번째 줄 */}
        <T.RowWrapper>
          <T.DirectionTit>&nbsp;&nbsp;&nbsp;왼쪽</T.DirectionTit>
          <T.StepSideItem>
            <div>{subTitLB} : </div>
            {leftBottom}
            <T.StepSideUnit>{unit}</T.StepSideUnit>
          </T.StepSideItem>
          <T.StepSideItem>
            <div>{subTitRB} : </div>
            {rightBottom}
            <T.StepSideUnit>{unit}</T.StepSideUnit>
          </T.StepSideItem>
        </T.RowWrapper>
      </T.StepSideBox>
      {grade && <EvalLabel status={grade} />}
    </>
  );
};

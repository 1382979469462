import { EvalLabel, EvalStatus } from "../../EvalLabel";
import * as S from "../walkResultItem.style";
import { ReactComponent as IconInfo } from "../../../../styles/assets/svg/icon-info.svg";

interface WalkResultItemProps {
  title?: string;
  grade?: EvalStatus;
  item?: any;
  children?: React.ReactElement;
  data?: number | string;
  evalData?: number;
  unit?: string;
  onClick?: any;
}

export const ExbodyItemColumn = ({
  title,
  grade,
  item,
  children,
  data,
  evalData,
  unit = "mm2",
  onClick,
}: WalkResultItemProps) => {
  return (
    <>
      <S.ItemHead>
        <S.TitBox onClick={onClick}>
          <span>
            {title}
            <IconInfo />
          </span>
        </S.TitBox>
        {grade && <EvalLabel status={grade} />}
      </S.ItemHead>
      {children && children}
      {!children && (
        <S.ContentBox>
          {data}
          <S.UnitSpan>{unit}</S.UnitSpan>
        </S.ContentBox>
      )}
    </>
  );
};

import { Notification, NotificationType } from "../../../pages/Notice/Notice";
import { NoticeItem } from "../../atoms/NoticeItem";
import * as S from "./noticeList.style";

interface NoticeListProps {
  notifications: Notification[];
  notificationType: NotificationType;
}
export const NoticeList = ({
  notifications,
  notificationType,
}: NoticeListProps) => {
  return (
    <>
      <S.NoticeList>
        {notifications.map((notification) => (
          <NoticeItem key={notification.id} notification={notification} />
        ))}
      </S.NoticeList>
      {notificationType === "userFcm" && (
        <S.NoticeTipBox>7일이 지난 항목은 자동으로 삭제됩니다.</S.NoticeTipBox>
      )}
    </>
  );
};

import * as S from "./missionCard.style";
import { ReactComponent as IconCrown } from "../../../styles/assets/svg/icon-crown-lg.svg";
import { ReactComponent as IconToken } from "../../../styles/assets/svg/icon-bonus-token.svg";
import { MissionBonus } from "../../../common/typs";

interface MissionCardProps {
  msssionBounus: MissionBonus;
}
export const MissionCard = ({ msssionBounus }: MissionCardProps) => {
  return (
    <S.MissionCardItem>
      <S.MissionDayBox
        bonusToken={msssionBounus.reward ? true : false}
        success={msssionBounus.success}
      >
        <S.MissionDaySpan>{msssionBounus.streak}일차</S.MissionDaySpan>
        <IconCrown />
      </S.MissionDayBox>
      <S.MissionBonusBox success={msssionBounus.success}>
        <IconToken width={16} height={16} />
        {msssionBounus.reward}
      </S.MissionBonusBox>
    </S.MissionCardItem>
  );
};

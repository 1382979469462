import styled from "styled-components";

export const MonitorSection = styled.section`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  gap: 8px;
`;

export const TitBox = styled.div`
  color: ${(props) => props.theme.colors.mainTxt};
  font-size: 1.6rem;
  font-weight: 700;
`;

import { ResultModalImg, ResultModalImgProps } from "..";
import * as S from "../resultModalImg.style";
import ImgFactorLeftRight01 from "../../../../styles/assets/img/img-factor-leftright.png";

export const ImgFactorLeftRight = ({ title }: ResultModalImgProps) => {
  return (
    <ResultModalImg title={title}>
      <>
        {/* 이미지 소스 */}
        <S.ImgBox>
          <img src={ImgFactorLeftRight01} alt="몸통 좌우 기울기 이미지" />
        </S.ImgBox>

        {/* 이미지 설명-1 */}
        <S.ContentBox>
          1. 고관절의 통증을 피하기 위한 운동
          <br />
          2. 고관절 외전근군의 근력 부족
          <br />
          3. 고관절 내전근군의 구축
          <br />
          4. 딱딱한 장경인대 단축
          <br />
          5. 체간 상부의 측만에 의한 비정상 자세
          <br />
          6. 입각지절의 단축
        </S.ContentBox>

        {/* 이미지 설명-2 */}
        <S.ContentBox>
          <S.SubtitBox>• 유각기때 몸통 좌우 기울기의 원인</S.SubtitBox>
          <S.TxtContentBox>
            1. 유각지절측의 골반을 들어올려 Toe Clearance를 얻기 위한 의도적
            운동
          </S.TxtContentBox>
          <S.TxtContentBox>
            2. 신체이미지 장애(푸셔 증후군으로 인한 체중심 벡터 인식장애)
          </S.TxtContentBox>
        </S.ContentBox>
      </>
    </ResultModalImg>
  );
};

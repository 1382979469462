import { useEffect, useState } from "react";
import { OptionItem } from "../../atoms/OptionItem";
import * as S from "./selectOption.style";

export interface SelectOption {
  id: string;
  name: string;
}

interface SelectOptionsProps {
  bgTheme: string;
  itemCount: number;
  options: SelectOption[];
  currentOption: string;
  setData: Function;
}
export const SelectOptions = ({
  bgTheme,
  itemCount,
  options,
  setData,
  currentOption,
}: SelectOptionsProps) => {
  return (
    <S.OptionList itemCount={itemCount} bgTheme={bgTheme}>
      {options.map((item: SelectOption, idx: number) => {
        return (
          <OptionItem
            bgTheme={bgTheme}
            data={item}
            key={item.id}
            active={currentOption === item.id ? true : false}
            onClick={() => setData(item.id)}
          />
        );
      })}
    </S.OptionList>
  );
};
